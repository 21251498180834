import React, { useEffect } from 'react';

import Colors from '../../constants/Colors';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';
import MoreInfo from '../common/MoreInfo';
import ReactGA from 'react-ga';
import SecondaryButton from '../common/SecondaryButton';
import Typography from '@material-ui/core/Typography';
import { faas } from '../../data/solutions';
import { makeStyles } from '@material-ui/core/styles';

const FMaaS = () => {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview('/faas');
    ReactGA.event({
      category: 'Soluciones',
      action: 'Page: Fmaas',
      label: 'Fmaas'
    });
  }, []);

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Grid container spacing={2} className={classes.titleContainer}>
          <Grid item xs={12} sm={6} className={classes.containerItem}>
            <Typography className={classes.mainTitle} variant='h4'>
              <strong>Fintech as a Service</strong>
            </Typography>
            <Typography className={classes.whiteSubtitle} variant='subtitle1'>
              {faas.subtitle}
            </Typography>
            <Grid container spacing={1} className={classes.homeButtons}>
              <Grid item xs={12}>
                <SecondaryButton text='Solicita tu demo' path='demo' caller='faas' id='ctaButton' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.whiteContainer}>
        <Typography className={classes.sectionTitle} variant='h5'>
          ¿De qué se trata?
        </Typography>
        <Grid container className={classes.infoContainer} spacing={2} alignItems='center' justify='center'>
          {faas.images.map((entry, index) =>
            index % 2 === 0 ? (
              <React.Fragment key={index}>
                <Grid item xs={12} md={6} className={classes.imageGrid}>
                  <img className={classes.image} src={entry} alt={`Imágen ${faas.titles[index]}`} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.itemTitle} variant='h5'>
                    {faas.titles[index]}
                  </Typography>
                  <Typography className={classes.subtitle} variant='subtitle1'>
                    {faas.texts[index]}
                  </Typography>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment key={index}>
                <Hidden mdUp>
                  <Grid item xs={12} md={6} className={classes.imageGrid}>
                    <img className={classes.image} src={entry} alt={`Imágen ${faas.titles[index]}`} />
                  </Grid>
                </Hidden>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.itemTitle} variant='h5'>
                    {faas.titles[index]}
                  </Typography>
                  <Typography className={classes.subtitle} variant='subtitle1'>
                    {faas.texts[index]}
                  </Typography>
                </Grid>
                <Hidden smDown>
                  <Grid item xs={12} md={6} className={classes.imageGrid}>
                    <img className={classes.image} src={entry} alt={`Imágen ${faas.titles[index]}`} />
                  </Grid>
                </Hidden>
              </React.Fragment>
            )
          )}
        </Grid>
      </div>
      <MoreInfo caller={'Fmaas'} />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    margin: 'auto',
    padding: '10% 0',
    backgroundColor: Colors.primary,
    backgroundImage: 'url(/products/faas/bg.svg)',
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-7%',
      backgroundImage: 'url(/products/faas/bg-mobile.svg)',
      padding: '22% 0'
    }
  },
  mainTitle: {
    color: Colors.secondary,
    fontWeight: 400,
    fontSize: '3.5em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.7em'
    }
  },
  titleContainer: {
    margin: 'auto',
    maxWidth: '94%',
    textAlign: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '36vh'
    }
  },
  containerItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  whiteSubtitle: {
    color: Colors.white,
    margin: '4% 0',
    fontSize: '1.4em',
    maxWidth: '90%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  homeButtons: {
    marginTop: '1%',
    maxWidth: '70%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  whiteContainer: {
    padding: '5% 12%',
    backgroundColor: Colors.white,
    [theme.breakpoints.down('sm')]: {
      padding: '15% 5%'
    }
  },
  sectionTitle: {
    textAlign: 'center',
    color: Colors.primary,
    fontWeight: 600,
    margin: '0 5% 5%'
  },
  subtitle: {
    color: Colors.gray,
    fontWeight: 300,
    margin: '3% 0',
    fontSize: '1.25em'
  },
  itemTitle: {
    margin: '2% 0',
    color: Colors.gray,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em'
    }
  },
  image: {
    maxWidth: '40%'
  },
  imageGrid: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  }
}));

export default FMaaS;
