import React, { useEffect } from 'react';

import Colors from '../../constants/Colors';
import Grid from '@material-ui/core/Grid';
import MoreInfo from '../common/MoreInfo';
import ReactGA from 'react-ga';
import SecondaryButton from '../common/SecondaryButton';
import SecondaryButtonLink from '../common/SecondaryButtonLink';
import Slider from 'react-slick';
import { Typography } from '@material-ui/core';
import { digitalAuthentication as dAuth } from '../../data/solutions';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';

const DigitalAuthentication = () => {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview('/digital-authentication');
    ReactGA.event({
      category: 'Soluciones',
      action: 'Page: Digital-Authentication',
      label: 'Digital-Authentication'
    });
  }, []);

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });

  const settings = {
    className: 'center',
    centerMode: isMobile ? false : true,
    infinite: true,
    dots: true,
    autoplay: true,
    centerPadding: '0px',
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplaySpeed: 4500
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Grid container spacing={2} className={classes.titleContainer}>
          {isMobile && (
            <Grid item xs={12} sm={6} className={classes.containerItem}>
              <img
                src={'./products/digital-verification.svg'}
                alt='Logo autenticación digital'
                className={classes.mainImage}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} className={classes.containerItem}>
            <Typography className={classes.mainTitle} variant='h4'>
              <strong>CarlaID</strong>
              <br />
              Digital Identity <br /> &amp; Verification
            </Typography>
            <Typography className={classes.blackSubtitle} variant='subtitle1'>
              {dAuth.subtitle}
            </Typography>
            <Grid container spacing={1} className={classes.homeButtons}>
              <Grid item xs={12}>
                <SecondaryButtonLink
                  text='Comienza ahora'
                  href='https://dashboard.financialomejor.com'
                  caller='digital-authentication'
                  id='ctaButton'
                />
              </Grid>
              <Grid item xs={12}>
                <SecondaryButton text='Solicita tu demo' path='demo' caller='digital-authentication' id='ctaButton' />
              </Grid>
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid item xs={12} sm={6} className={classes.containerItem}>
              <img
                src={'./products/digital-verification.svg'}
                alt='Logo autenticación digital'
                className={classes.mainImage}
              />
            </Grid>
          )}
        </Grid>
      </div>
      <div className={classes.containerInfoWhite}>
        <Typography className={classes.secondaryTitle} variant='h4'>
          {dAuth.description}
        </Typography>
        <Typography className={classes.subtitle} variant='subtitle1'>
          {dAuth.description2}
        </Typography>
      </div>
      <div className={classes.containerInfo}>
        <Typography className={classes.benefitsTitle} variant='h4'>
          Beneficios
        </Typography>
        <hr style={{ margin: '5% 0' }} />
        <Grid container spacing={2}>
          {dAuth.benefits.map((benefit, index) => (
            <Grid key={index} item xs={12} md={4}>
              <img src={dAuth.benefitImages[index]} alt={`Imágen ${index}`} className={classes.benefitImages} />
              <Typography className={classes.subtitle} variant='subtitle1'>
                {benefit}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={classes.containerTitle}>
        <Typography className={classes.benefitsTitle} variant='h4'>
          Características
        </Typography>
        <hr style={{ margin: '5% 0 0' }} />
      </div>
      <div className={classes.containerCharacteristics}>
        <div style={{ overflow: 'hidden' }}>
          <div className={classes.sliderContainer}>
            <Slider {...settings}>
              {dAuth.characteristics.map((c, index) => (
                <div key={index} className={classes.slideWrapper}>
                  <img
                    className={classes.slideImage}
                    src={dAuth.characteristicImages[index]}
                    alt={`Imágen ${dAuth.characteristicTitles[index]}`}
                  />
                  <Typography variant='subtitle1' className={classes.whiteText}>
                    <strong>{dAuth.characteristicTitles[index]}: </strong>
                    {c}
                  </Typography>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <MoreInfo caller={'Digital-Authentication'} />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    margin: 'auto',
    padding: '10% 0',
    backgroundImage: 'url(/products/digital-lending/bg.svg)',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: Colors.white,
    [theme.breakpoints.up('lg')]: {
      padding: '10% 0 15%'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-7%',
      padding: '25% 0 15%',
      backgroundSize: '100% 40%'
    }
  },
  containerInfo: {
    padding: '3% 15% 5%',
    backgroundColor: '#eceeff',
    [theme.breakpoints.down('sm')]: {
      padding: '10% 10%'
    }
  },
  containerInfoWhite: {
    padding: '5% 15% 4%',
    backgroundColor: Colors.white,
    [theme.breakpoints.down('sm')]: {
      padding: '10% 10%'
    }
  },
  containerTitle: {
    backgroundColor: Colors.white,
    padding: '3% 15% 2%',
    [theme.breakpoints.down('sm')]: {
      padding: '10% 10% 0'
    }
  },
  containerCharacteristics: {
    backgroundColor: Colors.white,
    padding: '2% 8% 4%',
    [theme.breakpoints.down('sm')]: {
      padding: '5% 2%'
    }
  },

  mainTitle: {
    color: Colors.primary,
    fontWeight: 400,
    fontSize: '3.5em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.7em',
      textAlign: 'center'
    }
  },
  secondaryTitle: {
    color: Colors.gray,
    fontSize: '2em',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6em'
    }
  },
  sliderBox: {
    width: '100%'
  },
  charactersticBox: {
    backgroundColor: Colors.primary
  },
  benefitsTitle: {
    marginTop: '5%',
    color: Colors.primary,
    fontSize: '2.3em',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9em'
    }
  },
  titleContainer: {
    margin: 'auto',
    maxWidth: '90%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '70%'
    }
  },
  containerItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  subtitle: {
    color: Colors.gray,
    fontWeight: 300,
    margin: '3% 0',
    fontSize: '1.4em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25em'
    }
  },
  blackSubtitle: {
    margin: '4% 0',
    fontSize: '1.4em',
    maxWidth: '70%',
    color: Colors.gray,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      maxWidth: '100%'
    }
  },
  mainImage: {
    margin: 'auto',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30%'
    }
  },
  btnInfo: {
    height: '45px',
    fontWeight: 600,
    textTransform: 'uppercase',
    background: Colors.yellow,
    fontSize: '14px',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginTop: '4px',
    transition: '0.2s',
    '&:hover': {
      background: Colors.lightGray
    }
  },
  homeButtons: {
    maxWidth: '70%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  benefitImages: {
    display: 'block',
    margin: 'auto',
    height: '12vh',
    marginBottom: '7%'
  },
  sliderContainer: {
    display: 'block',
    margin: '20px auto'
  },
  slideWrapper: {
    overflow: 'visible',
    backgroundColor: Colors.primary,
    padding: '10%',
    width: '300px',
    borderRadius: '40px',
    marginTop: '3vh',
    marginBottom: '5%',
    borderColor: Colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginBottom: '2%'
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '30vh'
    }
  },
  slideImage: {
    width: '20%',
    margin: 'auto',
    padding: '5% 0'
  },
  whiteText: {
    color: Colors.white,
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25em'
    }
  },
  startNowButton: {
    color: Colors.primary,
    textAlign: 'center',
    marginTop: 10
  }
}));

export default DigitalAuthentication;
