import Colors from '../../constants/Colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import Footer from '../common/Footer';
import Header from '../common/Header';
import LandingPageRouter from './LandingPageRouter';
import MaintenanceView from '../maintenance/MaintenanceView';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    backgroundColor: Colors.lightGray,
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(1),
  },
  footer: {
    marginTop: 'auto',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 6,
    color: 'white !important',
    backgroundColor: '#4FCE5D',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#4FCE5D',
    },
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        backgroundColor: Colors.secondary,
      },
    },
  },
  wa: {
    color: Colors.white,
    maxWidth: '100%',
    paddingTop: '5px',
  },
}));

const LandingPage = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {process.env.REACT_APP_FOR_MAINTENANCE ? (
        <MaintenanceView />
      ) : (
        <React.Fragment>
          <CssBaseline />
          <div component="main" className={classes.main}>
            <Header {...props} />
            <LandingPageRouter {...props} />
          </div>
          <footer className={classes.footer}>
            <Footer />
          </footer>
        </React.Fragment>
      )}
    </div>
  );
};

export default LandingPage;
