import { Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { validateNumericInput } from '../../controllers/validators';

export const DocumentForm = ({ data, setData, onHide, onSearch }) => {
  const [errors, setErrors] = useState({ identification: false });

  const handleChange = (name, value) => {
    data[name] = value;
    setData({ ...data });
  };

  const handleSelect = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const handleNumber = (event) => {
    const error = !validateNumericInput(event.target.value);
    errors[event.target.name] = error;
    if (!error) handleChange(event.target.name, parseInt(event.target.value));
    else handleChange(event.target.name, event.target.value);
    setErrors({ ...errors });
  };

  const handleText = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  return (
    <Paper style={{ padding: '2% 5% 6%', borderRadius: '20px' }}>
      <FormControl fullWidth variant='outlined' margin='normal'>
        <InputLabel>Tipo de documento</InputLabel>
        <Select
          fullWidth
          value={data.documentType || ''}
          onChange={handleSelect}
          style={{ textAlign: 'left' }}
          name='documentType'
          label='Tipo de documento'>
          <MenuItem value='cc'>Cédula de ciudadanía</MenuItem>
          <MenuItem value='ce'>Cédula de extranjería</MenuItem>
          <MenuItem value='license'>Licencia de conducción</MenuItem>
          <MenuItem value='nit'>NIT</MenuItem>
        </Select>
      </FormControl>
      <TextField
        margin='normal'
        variant='outlined'
        type='number'
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        name='identification'
        label='Número de identificación'
        fullWidth
        error={errors.identification}
        helperText={errors.identification ? 'Revisa que sea un número válido' : null}
        onChange={handleNumber}
        value={data.identification || ''}
        autoComplete='off'
      />
      <TextField
        margin='normal'
        variant='outlined'
        type='text'
        name='documentId'
        label='ID del documento'
        fullWidth
        onChange={handleText}
        value={data.documentId || ''}
        autoComplete='off'
      />
      <div style={{ textAlign: 'center', marginTop: '5%' }}>
        <Button style={{ margin: '0 2%' }} variant='contained' id='hideButton' onClick={onHide}>
          Ocultar
        </Button>
        {data.documentId && data.identification && data.documentId && !errors.identification && !errors.documentId && (
          <Button style={{ margin: '0 2%' }} variant='contained' onClick={onSearch} id='searchButton' color='primary'>
            {' '}
            Buscar{' '}
          </Button>
        )}
      </div>
    </Paper>
  );
};

DocumentForm.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  onHide: PropTypes.func,
  onSearch: PropTypes.func
};
