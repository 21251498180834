export const findUserContract = async (documentId, identification, documentType) => {
  const response = await fetch(`${process.env.REACT_APP_SERVER_HOST}/dashboard/client-contract`, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      documentId, identification, documentType
    }),
  });
  const data = await response.json();
  return data;
}