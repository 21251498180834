import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';

import { Info } from '@material-ui/icons';
import React from 'react';

const UnavailableInformation = () => (
  <List>
    <ListItem>
      <ListItemAvatar>
        <Info />
      </ListItemAvatar>
      <ListItemText primary="No hay información disponible" />
    </ListItem>
  </List>
);

export default UnavailableInformation;
