module.exports = {
  primary: '#403999',
  secondary: '#8EECFF',
  accent: '#cc3f57',
  white: '#fff',
  gray: '#5c5c5c',
  lightGray: '#f0f0f0',
  mediumGray: '#d1d1d1',
  yellow: '#ffd500'
};
