import React, { useEffect } from 'react';

import Colors from '../../constants/Colors';
import Grid from '@material-ui/core/Grid';
import MoreInfo from '../common/MoreInfo';
import ReactGA from 'react-ga';
import Typography from '@material-ui/core/Typography';
import SecondaryButton from '../common/SecondaryButton';
import { digitalLending as dLending } from '../../data/solutions';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';

const DigitalLending = () => {
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview('/digital-lending');
    ReactGA.event({
      category: 'Soluciones',
      action: 'Page: Digital-Lending',
      label: 'Digital-Lending',
    });
  }, []);

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Grid container spacing={2} className={classes.titleContainer}>
          {isMobile && (
            <Grid item xs={12} sm={6} className={classes.containerItem}>
              <img
                src={'products/digital-lending.svg'}
                alt="Logo autenticación digital"
                className={classes.mainImage}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} className={classes.containerItem}>
            <Typography className={classes.mainTitle} variant="h4">
              <strong>Digital Lending</strong>
            </Typography>
            <Typography className={classes.blackSubtitle} variant="subtitle1">
              Tecnología para mejorar la experiencia de tus clientes
            </Typography>
            <Grid container spacing={1} className={classes.homeButtons}>
              <Grid item xs={12}>
                <SecondaryButton text="Comienza ahora" path="demo" caller="digital-lending" id="ctaButton" />
              </Grid>
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid item xs={12} sm={6} className={classes.containerItem}>
              <img
                src={'products/digital-lending.svg'}
                alt="Logo autenticación digital"
                className={classes.mainImage}
              />
            </Grid>
          )}
        </Grid>
      </div>
      <div className={classes.containerInfo}>
        <Typography className={classes.secondaryTitle} variant="h4">
          {dLending.first.description}
        </Typography>
      </div>
      <div className={classes.lightGrayContainer}>
        <Typography className={classes.benefitsTitle} variant="h4">
          {dLending.first.title}
        </Typography>
        <Grid container spacing={2}>
          {dLending.first.texts.map((benefit, index) => (
            <Grid key={index} item xs={12} md={6}>
              <img src={dLending.first.images[index]} alt={`Imágen ${index}`} className={classes.benefitImages} />
              <Typography className={classes.subtitle} variant="subtitle1">
                {benefit}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={classes.containerInfo}>
        <Typography className={classes.benefitsTitle} variant="h4">
          {dLending.second.title}
        </Typography>
        <Typography className={classes.secondaryTitle} variant="h4">
          {dLending.second.subtitle}
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle1">
          {dLending.second.text}
        </Typography>
      </div>
      <MoreInfo caller={'Digital-Lending'} />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    margin: 'auto',
    padding: '10% 0',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: 'url(/products/digital-lending/bg.svg)',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: Colors.white,
    [theme.breakpoints.up('lg')]: {
      padding: '10% 0 15%',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-7%',
      padding: '25% 0 15%',
      backgroundSize: '100% 40%',
    },
  },
  mainTitle: {
    color: Colors.primary,
    fontWeight: 400,
    fontSize: '3.5em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.7em',
      textAlign: 'center',
    },
  },
  titleContainer: {
    margin: 'auto',
    maxWidth: '90%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '70%',
    },
  },
  containerItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  subtitle: {
    color: Colors.gray,
    fontSize: '1.25em',
    fontWeight: 300,
    margin: '3% 0',
  },
  whiteSubtitle: {
    margin: '4% 0',
    fontSize: '1.4em',
    maxWidth: '90%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      fontSize: '1.25em',
    },
  },
  mainImage: {
    margin: 'auto',
    width: '50%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30%',
    },
  },
  homeButtons: {
    maxWidth: '75%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  blackSubtitle: {
    margin: '4% 0',
    fontSize: '1.4em',
    maxWidth: '75%',
    color: Colors.gray,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      textAlign: 'center',
    },
  },
  grayText: {
    margin: '2% 0',
    fontWeight: 300,
    color: Colors.gray,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      fontSize: '1.25em',
    },
  },
  containerInfo: {
    padding: '5% 15%',
    backgroundColor: Colors.white,
    [theme.breakpoints.down('sm')]: {
      padding: '5% 10%',
    },
  },
  lightGrayContainer: {
    padding: '5% 15%',
    backgroundColor: '#eceeff',
    [theme.breakpoints.down('sm')]: {
      padding: '5% 10%',
    },
  },
  secondaryTitle: {
    color: Colors.gray,
    fontSize: '1.8em',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em',
    },
  },
  benefitsTitle: {
    margin: '2% 0 4%',
    color: Colors.primary,
    fontSize: '2.3em',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
    },
  },
  benefitImages: {
    display: 'block',
    margin: 'auto',
    height: '12vh',
    marginBottom: '7%',
  },
}));

export default DigitalLending;
