import AboutUs from '../about/AboutUs';
import Contact from '../common/Contact';
import Demo from '../common/Demo';
import DemoComplete from '../common/DemoComplete';
import DigitalAuthentication from '../products/DigitalAuthentication';
import DigitalChecks from '../products/DigitalChecks';
import DigitalDocumentation from '../products/DigitalDocumentation';
import DigitalLending from '../products/DigitalLending';
import FAQ from '../about/FAQ';
import FMaaS from '../products/FMaaS';
import Home from './Home';
import MainPageWrapper from './MainPageWrapper';
import React from 'react';
import { Route } from 'react-router-dom';
import TermsAndConditions from '../about/TermsAndConditions';

const LandingPage = () => {
  return (
    <React.Fragment>
      <Route exact path="/" component={MainPageWrapper} />
      <Route exact path="/digital-verification" component={DigitalAuthentication} />
      <Route exact path="/faas" component={FMaaS} />
      <Route exact path="/digital-lending" component={DigitalLending} />
      <Route exact path="/digital-signature" component={DigitalDocumentation} />
      <Route exact path="/digital-checks" component={DigitalChecks} />
      <Route exact path="/credito" component={Home} />
      <Route exact path="/contacto" component={Contact} />
      <Route exact path="/demo" component={Demo} />
      <Route exact path="/demo/completo" component={DemoComplete} />
      <Route exact path="/nosotros" component={AboutUs} />
      <Route exact path="/preguntas-frecuentes" component={FAQ} />
      <Route exact path="/terminos-y-condiciones" component={TermsAndConditions} />
    </React.Fragment>
  );
};

export default LandingPage;
