import { Checkbox, Chip, Grid, Input, ListItemText } from '@material-ui/core';
import React, { useState } from 'react';
import { validateAlphabeticInput, validateEmailInput, validatePhoneInput } from '../../controllers/validators';

import Button from '@material-ui/core/Button';
import Colors from '../../constants/Colors';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import ReactGA from 'react-ga';
import { Redirect } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { list } from '../../data/solutions';
import { makeStyles } from '@material-ui/core/styles';

const Demo = () => {
  const classes = useStyles();
  const [contactInfo, setContactInfo] = useState({ data: { products: [], financialInstitution: false }, errors: {} });
  const [redirect, setRedirect] = useState(false);

  const handleSend = () => {
    fetch(`${process.env.REACT_APP_SERVER_HOST}/funnel/demo`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: { ...contactInfo.data }
      })
    })
      .then(() => {
        contactInfo.data = { products: [], financialInstitution: false };
        setContactInfo({ ...contactInfo });
        ReactGA.event({
          category: 'Soluciones',
          action: 'Demo solicitado'
        });
      })
      .catch((err) => {
        alert(err);
      });
    setRedirect(true);
  };

  const handleChange = (name, value, error) => {
    contactInfo.data[name] = value;
    contactInfo.errors[name] = error;
    setContactInfo({ ...contactInfo });
  };

  const handleProductsChange = (event) => {
    contactInfo.data.products = event.target.value;
    setContactInfo({ ...contactInfo });
  };

  const handleSelect = (event) => {
    handleChange(event.target.name, event.target.value, false);
  };

  const handleText = (event) => {
    handleChange(event.target.name, event.target.value, false);
  };

  const handleEmail = (event) => {
    const error = !validateEmailInput(event.target.value);
    handleChange(event.target.name, event.target.value, error);
  };

  const handlePhone = (event) => {
    const error = !validatePhoneInput(event.target.value);
    handleChange(event.target.name, event.target.value, error);
  };

  const handleName = (event) => {
    const error = !validateAlphabeticInput(event.target.value);
    handleChange(event.target.name, event.target.value, error);
  };

  const handleContent = (event) => {
    const error = event.target.value.length === 800;
    if (event.target.value.length <= 800) handleChange(event.target.name, event.target.value, error);
  };

  const validateForm = () => {
    const data = contactInfo.data;
    const errors = contactInfo.errors;
    return (
      data.name &&
      data.phone &&
      data.email &&
      data.company &&
      data.job &&
      data.products.length > 0 &&
      !(errors.name || errors.email || errors.phone || errors.comments)
    );
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: '/demo/completo',
          state: { name: contactInfo.data.name, email: contactInfo.data.email }
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <div className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography className={classes.mainTitle}>Solicita un Demo</Typography>

          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  name='name'
                  label='¿Cómo te llamas?'
                  fullWidth
                  autoComplete='name'
                  value={contactInfo.data.name || ''}
                  error={contactInfo.errors.name}
                  helperText={contactInfo.errors.name ? 'Revisa que tu nombre solo tenga letras' : null}
                  onChange={handleName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  name='company'
                  label='¿En qué empresa trabajas?'
                  fullWidth
                  value={contactInfo.data.company || ''}
                  onChange={handleText}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id='simple-select-outlined-label'>¿Trabajas en una insititución financiera?</InputLabel>
                  <Select
                    fullWidth
                    labelId='simple-select-outlined-label'
                    value={contactInfo.data.financialInstitution}
                    name='financialInstitution'
                    onChange={handleSelect}
                    label='¿Trabajas en una insititución financiera?'>
                    <MenuItem value={true}>Si</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  required
                  name='job'
                  label='Cúal es tu rol en la empresa?'
                  fullWidth
                  value={contactInfo.data.job || ''}
                  onChange={handleText}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  label='Correo electrónico'
                  name='email'
                  autoComplete='email'
                  error={contactInfo.errors.email}
                  helperText={contactInfo.errors.email ? 'Ingresa un correo válido' : null}
                  onChange={handleEmail}
                  value={contactInfo.data.email || ''}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id='phone'
                  name='phone'
                  type='tel'
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  label='Número de celular'
                  fullWidth
                  autoComplete='tel'
                  error={contactInfo.errors.phone}
                  helperText={contactInfo.errors.phone ? 'Ingresa un número válido' : null}
                  onChange={handlePhone}
                  value={contactInfo.data.phone || ''}
                  InputProps={{
                    startAdornment: <Chip label='+57' color='secondary' className={classes.indicator} />
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel id='mutiple-checkbox-label'>¿En qué productos estás interesado?</InputLabel>
                  <Select
                    labelId='mutiple-checkbox-label'
                    id='interestedProducts'
                    multiple
                    value={contactInfo.data.products}
                    color='primary'
                    MenuProps={{
                      getContentAnchorEl: () => null
                    }}
                    onChange={handleProductsChange}
                    input={<Input />}
                    renderValue={(selected) => selected.join(',  ')}>
                    {list.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        <Checkbox color='primary' checked={contactInfo.data.products.indexOf(item.name) > -1} />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Comentarios adicionales'
                  multiline
                  rows='5'
                  name='comments'
                  error={contactInfo.errors.comments}
                  helperText={contactInfo.errors.comments ? 'Ingresa un mensaje de máximo 800 caracteres' : null}
                  onChange={handleContent}
                  value={contactInfo.data.comments || ''}
                />
              </Grid>
            </Grid>
            {validateForm() && (
              <Button
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                id='sendButton'
                onClick={handleSend}>
                Enviar
              </Button>
            )}
          </form>
        </Paper>
      </div>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '90vh'
  },
  mainTitle: {
    color: Colors.primary,
    fontSize: '3em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.3em'
    },
    textAlign: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  indicator: {
    marginRight: 10
  },
  layout: {
    minHeight: '68vh',
    width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(900 + theme.spacing(1) * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-7%',
      marginBottom: '3%'
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(2),
    overflow: 'auto'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

export default Demo;
