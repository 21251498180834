import Home from './Home';
import React from 'react';
import Solutions from './Solutions';
import ProductIntro from './ProductIntro.jsx';
import MoreInfo from '../common/MoreInfo';

const MainPageWrapper = () => {
  return (
    <React.Fragment>
      <Home />
      <ProductIntro />
      <Solutions />
      <MoreInfo from="landingPage" />
    </React.Fragment>
  );
};

export default MainPageWrapper;
