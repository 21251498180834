import React from 'react';
import { Grid, Typography, Link, Collapse, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';

import { list } from '../../data/solutions';

import Colors from '../../constants/Colors';

const Solutions = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const classes = useStyles();
  const [productDetail, setProductDetail] = React.useState( () => {
    if (isMobile) return {};
    else return list.find(item => item.link === 'faas')
  });

  const handleClick = (event) => {
    event.preventDefault();
    let selectedProduct = list.find(item => item.link === event.currentTarget.name);
    setProductDetail(selectedProduct);
  }

  return (

    <div className={classes.container}>
        <Typography id="solutions" className={classes.sectionTitle} variant="h5">Soluciones para tu empresa</Typography>
        <Grid container className={classes.infoContainer} spacing={2} alignItems="center" justify="center">
          <Grid item xs={12} md={12} className={classes.item1}>
            <Grid container spacing={1} alignItems="center" justify="center">
            
            {isMobile && list.map((product)=>(
              <React.Fragment key={product.link}>
                <Grid item xs={12} md={12} className={classes.containerItem}>
                  <Link className={classes.productLink} name={product.link} onClick={handleClick}>
                    <Paper className={productDetail.link === product.link ? classes.productBoxSelected: classes.productBox} elevation={5}>
                    <Grid container spacing={2} alignItems="center" justify="center">
                        <Grid item xs={3}>
                          <img className={classes.productImage} src={`products/${product.image || product.link}.svg`} alt={product.name}/>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography className={classes.productName} variant="subtitle1">{product.name}</Typography>
                        </Grid>
                        { isMobile &&
                          <Grid item xs={2}>
                            <ExpandMoreIcon color={productDetail.link !== product.link ? 'primary': 'secondary'}/>
                        </Grid>
                        }
                        <Grid item xs={12}>
                        <Typography className={classes.blackSubtitle} variant="subtitle1">{product.description}</Typography>
                        </Grid>
                      </Grid> 
                    </Paper>
                  </Link>
                </Grid>
                <Collapse in={productDetail.link === product.link} >
                  <Grid item xs={12} md={12} className={classes.item2}>
                    <br/>
                      <Typography className={classes.blackSubtitle} variant="subtitle1">
                        {productDetail.moreInfo}
                      </Typography>
                      {
                        productDetail.link && productDetail.link.includes('https') ?
                        <Link href={productDetail.link} className={classes.primaryLink}>
                          <div className={classes.btnInfo}>Ver más <ArrowForwardIcon className={classes.arrow}/></div>
                        </Link>
                        :
                        <NavLink to={`/${productDetail.link}`} className={classes.primaryLink}>
                          <div className={classes.btnInfo}>Ver más <ArrowForwardIcon className={classes.arrow}/></div>
                        </NavLink>
                      }
                      
                  </Grid>
                </Collapse>
              </React.Fragment>
            ))}
            {!isMobile && list.map((product)=>(
            <Grid key={product.link} item sm={6} md={3} className={classes.containerItemWeb}>
              <Link className={classes.productLink} name={product.link} onClick={handleClick}>
                <Paper elevation={5} className={productDetail.link === product.link ? classes.productBoxSelected: classes.productBox}>
                  <img className={classes.productImage} src={`products/${product.image || product.link}.svg`} alt={product.name}/>
                  <Typography className={classes.productName} variant="subtitle1">{product.name}</Typography>
                  <Typography className={classes.blackSubtitle2} variant="subtitle1">{product.description}</Typography>
                </Paper>
              </Link>
          </Grid>
            ))}
            </Grid>
          </Grid>
          {!isMobile &&
          <Grid item xs={12} md={12} className={classes.item2}>
            <br/>
            <br/>
              <Typography className={classes.blackSubtitle} variant="subtitle1">
                {productDetail.moreInfo}
              </Typography>
              {
                productDetail.link && productDetail.link.includes('https') ?
                <Link href={productDetail.link} className={classes.primaryLink}>
                  <div className={classes.btnInfo}>Ver más <ArrowForwardIcon className={classes.arrow}/></div>
                </Link>
                :
                <NavLink to={`/${productDetail.link}`} className={classes.primaryLink}>
                  <div className={classes.btnInfo}>Ver más <ArrowForwardIcon className={classes.arrow}/></div>
                </NavLink>
              }
              
          </Grid>
          }
        </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
    padding: '5% 0',
    backgroundColor: Colors.white,
    [theme.breakpoints.down('sm')]: {
      padding: '20% 0 10%',
    }
  },

  infoContainer: {
    borderRadius: '40px',
    border: '',
    margin: 'auto',
    maxWidth: '100%',
  },
  containerItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  },
  sectionTitle: {
    textAlign: 'center',
    color: Colors.primary,
    fontWeight: 600,
    margin: '5% 0 4%',
    [theme.breakpoints.down('sm')]: {
      margin: '0% 10% 5%',
    }
  },
  mainTitle: {
    textAlign: 'center',
    color: Colors.gray,
    marginBottom: '2%',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      margin: '0 5%',
    },
  },
  productTitle: {
    margin: '2% 0 5%',
    color: Colors.primary,
    fontWeight: 600,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },
  blackSubtitle: {
    color: 'black',
    lineHeight: 2,
    fontSize: '1.25em',
    fontWeight: 300,
    margin:'auto',
    textAlign: 'left',
    maxWidth: '90vw',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-1%',
      fontSize: '1.15em',
      padding: '0 3% 3%',
    }
  },
  blackSubtitle2: {
    color: 'black',
    lineHeight: 1.5,
    fontSize: '1.15em',
    fontWeight: 300,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-1%',
      fontSize: '1.15em',
      padding: '0 3% 3%',
    }
  },
  productName: {
    color: Colors.primary,
    lineHeight: 1.3,
    fontSize: '1.4em',
    margin: '2% 0',
    fontWeight: 500,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3em',
    },
  },
  productBox: {
    transition: '0.2s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '30px',
    maxWidth: '100%',
    '&:hover': {
      backgroundColor: Colors.lightGray
    },
    [theme.breakpoints.up('sm')]: {
      padding: '4% 4% 4% 7%',
      height: '38vh',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '1%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '20px',
    }
  },
  productBoxSelected: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: Colors.primary,
    backgroundColor: Colors.secondary,
    borderWidth: '3px',
    borderRadius: '30px',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: '4% 4% 4% 7%',
      height: '38vh',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '1%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderStyle: 'solid',
      border: Colors.secondary,
      borderWidth: '3px',
      borderRadius: '20px',
    }
  },
  productImage: {
    maxHeight: '9vh',
    [theme.breakpoints.down('sm')]: {
      padding: '2%'
    }
  },
  productLink: {
    textDecoration: 'none !important',
    cursor: 'pointer',
  },
  btnInfo: {
    height: '45px',
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '14px',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginTop: '3%',
    backgroundSize: '300% 100%',
    'moz-transition': 'all .4s ease-in-out',
    '-o-transition': 'all .4s ease-in-out',
    '-webkit-transition': 'all .4s ease-in-out',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundPosition: '100% 0',
      'moz-transition': 'all .4s ease-in-out',
      '-o-transition': 'all .4s ease-in-out',
      '-webkit-transition': 'all .4s ease-in-out',
      transition: 'all .4s ease-in-out'
    },
  },
  color1: {
    backgroundImage: 'linear-gradient(to right, #ffdd00 0%, #f8b500 51%, #ffdd00 100%)',
  },
  color2: {
    color: Colors.white,
    backgroundImage: 'linear-gradient(to right, #0062f5 0%, #0c46c2 51%, #0062f5 100%)',
  },
  primaryLink: {
    width: '24%',
    textDecoration: 'none !important',
    color: Colors.primary,
    fontWeight: 500,
    margin: 'auto',
    marginBottom: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '70%',
    },
  },
  item2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  },
  arrow: {
    marginLeft: '5px',
    height: '0.7em'
  }
}));

export default Solutions;
