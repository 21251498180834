import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  Fade,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Colors from '../../constants/Colors';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MediaQuery from 'react-responsive';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { list } from '../../data/solutions.js';

const Header = ({ history }) => {
  const classes = useStyles();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [anchorProducts, setAnchorProducts] = React.useState(null);
  const [anchorUs, setAnchorUs] = React.useState(null);

  const handleClickProducts = (event) => {
    setAnchorProducts(event.currentTarget);
  };

  const handleClickUs = (event) => {
    setAnchorUs(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorProducts(null);
    setAnchorUs(null);
  };

  const handleRedirect = (route) => {
    history.push(route);
    setOpenMobileMenu(false);
  };

  return (
    <React.Fragment>
      <Drawer
        scroll='no'
        anchor={'right'}
        elevation={0}
        open={openMobileMenu}
        onClose={() => setOpenMobileMenu(false)}
        PaperProps={{
          className: classes.drawer,
          style: {
            width: '100%',
            backgroundColor: Colors.white,
            marginBottom: 'auto',
            height: '90vh',
            overflow: 'hidden'
          }
        }}>
        <List>
          <ListItem style={{ padding: 0 }}>
            <ListItemText disableTypography className={classes.menuTitle} primary={'Soluciones'} />
          </ListItem>

          {list.map((solution, index) => {
            return (
              <ListItem
                key={index}
                button
                component='button'
                onClick={() => handleRedirect(`/${solution.link}`)}
                style={{ padding: 0 }}
                id={`${solution.link}Button`}>
                <ListItemText disableTypography className={classes.menuItem} primary={solution.name} />
              </ListItem>
            );
          })}

          <Divider className={classes.divider} />

          <ListItem style={{ padding: 0 }}>
            <ListItemText disableTypography className={classes.menuTitle} primary={'Nosotros'} id='usButton' />
          </ListItem>

          <ListItem
            button
            component='button'
            onClick={() => handleRedirect(`/nosotros`)}
            style={{ padding: 0 }}
            id='aboutUsButton'>
            <ListItemText disableTypography className={classes.menuItem} primary='Quiénes Somos' />
          </ListItem>

          <ListItem
            button
            component='button'
            onClick={() => handleRedirect(`/preguntas-frecuentes`)}
            style={{ padding: 0 }}
            id='frequentQuestionsButton'>
            <ListItemText disableTypography className={classes.menuItem} primary='Preguntas Frecuentes' />
          </ListItem>

          <Divider className={classes.divider} />

          <ListItem style={{ padding: 0 }}>
            <ListItemText disableTypography className={classes.menuTitle} primary={'Desarrolladores'} />
          </ListItem>

          <ListItem
            button
            component='button'
            href='https://developers.carla.tech/'
            target='_blank'
            style={{ padding: 0 }}
            id='docsButton'>
            <ListItemText disableTypography className={classes.menuItem} primary='API' />
          </ListItem>

          <ListItem
            button
            component='a'
            className={classes.btnTelephoneMobile}
            href='https://dashboard.financialomejor.com/'
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'none' }}
            onClick={() => handleRedirect('/')}
            id='singInButton'>
            <ListItemText className={classes.menuItem} primary={'Iniciar Sesión'} />
          </ListItem>
        </List>
      </Drawer>
      <div className={classes.root}>
        <AppBar className={openMobileMenu ? classes.appBarMobile : classes.appBar} elevation={0} color='inherit'>
          <MediaQuery minDeviceWidth={801}>
            <Toolbar className={classes.topBar}>
              <div className={classes.header}>
                <RouterLink to='/'>
                  <img src='./images/CarlaTech.svg' alt='Logo Carla Tech' width='200' height='100%' id='fmLogo' />
                </RouterLink>
              </div>
              <Button className={classes.link} href='https://developers.carla.tech/' target='_blank' id='docsButton'>
                <Typography className={classes.titleHeader}>Api</Typography>
              </Button>
              <Button className={classes.link} onClick={handleClickProducts} id='solutionsButton'>
                <Box className={classes.linkBox}>
                  <Typography className={classes.titleHeader}>Soluciones</Typography>
                  <KeyboardArrowDownIcon />
                </Box>
              </Button>
              <StyledMenu
                anchorEl={anchorProducts}
                open={Boolean(anchorProducts)}
                onClose={handleClose}
                disableAutoFocusItem={true}>
                {list
                  .filter((entry) => !entry.link.includes('https'))
                  .map((solution, index) => (
                    <NavLink
                      key={index}
                      to={`/${solution.link}`}
                      className={classes.primaryLink}
                      onClick={handleClose}
                      id={`${solution.link}Button`}>
                      <StyledMenuItem>
                        <ListItemText primary={solution.name} className={classes.black} />
                      </StyledMenuItem>
                    </NavLink>
                  ))}
              </StyledMenu>

              <Button className={classes.link} onClick={handleClickUs} id='usButton'>
                <Box className={classes.linkBox}>
                  <Typography className={classes.titleHeader}>Nosotros</Typography>
                  <KeyboardArrowDownIcon />
                </Box>
              </Button>
              <StyledMenu
                anchorEl={anchorUs}
                open={Boolean(anchorUs)}
                onClose={handleClose}
                disableAutoFocusItem={true}>
                <NavLink to={`/nosotros`} className={classes.primaryLink} onClick={handleClose} id='aboutUsButton'>
                  <StyledMenuItem>
                    <ListItemText primary='Quiénes somos' className={classes.black} />
                  </StyledMenuItem>
                </NavLink>
                <NavLink
                  to={`/preguntas-frecuentes`}
                  className={classes.primaryLink}
                  onClick={handleClose}
                  id='frequentQuestionsButton'>
                  <StyledMenuItem>
                    <ListItemText primary='Preguntas frecuentes' className={classes.black} />
                  </StyledMenuItem>
                </NavLink>
              </StyledMenu>

              <Link href='https://dashboard.financialomejor.com/' className={classes.primaryLink} id='signInButton'>
                <div className={classNames(classes.btnContact, classes.color1)}>Iniciar Sesión</div>
              </Link>
            </Toolbar>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={800}>
            <Toolbar className={classes.toolbarMobile}>
              <div className={classes.headerMobile}>
                <RouterLink to='/'>
                  <img
                    className={classes.logo}
                    src='./images/CarlaTech.svg'
                    alt='Logo Carla Tech'
                    width='180'
                    height='auto'
                    id='fmLogo'
                  />
                </RouterLink>
              </div>
              <div>
                {!openMobileMenu ? (
                  <div>
                    <Fade in={!openMobileMenu} timeout={600}>
                      <IconButton
                        edge='start'
                        className={classes.menuButton}
                        color='inherit'
                        aria-label='menu'
                        id='mobileMenuOpenButton'
                        onClick={() => setOpenMobileMenu(true)}>
                        <MenuIcon />
                      </IconButton>
                    </Fade>
                  </div>
                ) : (
                  <div className={classes.containerMobile}>
                    <IconButton
                      edge='start'
                      className={classes.menuButtonClose}
                      color='inherit'
                      aria-label='menu'
                      id='mobileMenuCloseButton'
                      onClick={() => setOpenMobileMenu(false)}>
                      X
                    </IconButton>
                    <Divider light={true} />
                  </div>
                )}
              </div>
            </Toolbar>
          </MediaQuery>
        </AppBar>
      </div>
    </React.Fragment>
  );
};

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black
      }
    }
  }
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1
  },
  drawer: {
    marginTop: theme.spacing(8)
  },
  link: {
    textDecoration: 'none',
    color: Colors.gray,
    transition: '0.3s',
    marginBottom: '-7px',
    '&:hover': {
      color: Colors.secondary
    }
  },
  blackLink: {
    textDecoration: 'none',
    color: Colors.gray,
    fontWeight: 500
  },
  black: {
    color: 'black'
  },
  primaryLink: {
    textDecoration: 'none !important',
    color: Colors.primary,
    fontWeight: 500,
    whiteSpace: 'nowrap'
  },
  menuButton: {
    color: Colors.primary,
    backgroundColor: Colors.secondary,
    position: 'absolute',
    top: '12%',
    right: '2%',
    bottom: 'auto'
  },
  menuButtonClose: {
    position: 'absolute',
    top: '12%',
    right: '2%',
    bottom: 'auto',
    padding: '3% 5%'
  },
  topBar: {
    height: theme.spacing(10),
    margin: 'auto',
    minWidth: '72vw'
  },
  appBarMobile: {
    height: '100vh'
  },
  header: {
    flexGrow: 1,
    marginTop: '5%',
    marginBottom: '5%',
    paddingRight: '8vw'
  },
  headerMobile: {
    display: 'inline-block',
    alignItems: 'center',
    position: 'relative'
  },
  toolbarMobile: {
    display: 'inline-block'
  },
  containerMobile: {
    height: '100%'
  },
  titleHeader: {
    fontSize: '16px',
    fontWeight: 500
  },
  titleHeaderMobile: {
    textAlign: 'center',
    padding: '2%',
    color: Colors.gray
  },
  btnTelephone: {
    background: 'transparent',
    display: 'flex',
    border: `3px solid ${Colors.secondary}`,
    borderRadius: '6px',
    height: '45px',
    color: Colors.gray,
    fontSize: '14px',
    width: '150px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '4px',
    marginLeft: '15px',
    transition: '0.3s',
    '&:hover': {
      borderColor: Colors.gray
    }
  },
  btnTelephoneMobile: {
    background: 'transparent',
    display: 'flex',
    border: `3px solid ${Colors.primary}`,
    borderRadius: '6px',
    height: '45px',
    marginTop: '15px',
    color: Colors.primary,
    fontSize: '14px',
    width: '70%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: 'auto',
    '&:hover': {
      borderColor: Colors.secondary
    }
  },
  btnContact: {
    marginLeft: '5%',
    textTransform: 'uppercase',
    height: '45px',
    background: Colors.secondary,
    fontSize: '14px',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginTop: '4px',
    backgroundSize: '300% 100%',

    'moz-transition': 'all .4s ease-in-out',
    '-o-transition': 'all .4s ease-in-out',
    '-webkit-transition': 'all .4s ease-in-out',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundPosition: '100% 0',
      'moz-transition': 'all .4s ease-in-out',
      '-o-transition': 'all .4s ease-in-out',
      '-webkit-transition': 'all .4s ease-in-out',
      transition: 'all .4s ease-in-out'
    }
  },
  color1: {
    backgroundImage: `linear-gradient(to right, ${Colors.secondary} 0%, #56daf5 51%, ${Colors.secondary} 100%)`
  },
  btnContactMobile: {
    height: '45px',
    background: Colors.primary,
    fontSize: '14px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '2% 0 2%',
    margin: 'auto',
    '&:hover': {
      background: Colors.white
    }
  },
  menuTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '17px',
    textAlign: 'center',
    color: Colors.primary,
    padding: '2% 0 0',
    margin: 0
  },
  menuItem: {
    fontWeight: 400,
    fontSize: '16px',
    textAlign: 'center',
    color: Colors.gray,
    padding: '2% 0'
  },
  divider: {
    backgroundColor: Colors.primary,
    margin: '2% 15%'
  },
  linkBox: {
    verticalAlign: 'middle',
    display: 'inline-flex'
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      padding: '10% 0 5%'
    }
  }
}));

Header.propTypes = {
  history: PropTypes.object
};
export default Header;
