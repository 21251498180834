import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import React, { useState } from 'react';

import Colors from '../../../constants/Colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import UnavailableInformation from '../../common/UnavailableInformation';
import { makeStyles } from '@material-ui/core/styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledExpandMoreIcon = () => <ExpandMoreIcon style={{ color: Colors.black }} />;

export default function ContractDocument({ contract }) {
  const classes = useStyles();
  const [expansionOpened, setExpansionOpened] = useState(true);
  const [numPages, setNumpages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const toggleExpansionOpened = () => {
    setExpansionOpened(!expansionOpened);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumpages(numPages);
  };

  const onPageLoadSuccess = () => {
    const canvas = document.getElementsByClassName('react-pdf__Page__canvas')[0];
    canvas.style.maxWidth = '100%';
    canvas.style.width = '100%';
    canvas.style.height = 'auto';
  };

  const nextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const previousPage = () => {
    setPageNumber(pageNumber - 1);
  };

  return (
    <Accordion expanded={expansionOpened} onChange={() => toggleExpansionOpened()}>
      <AccordionSummary
        className={classes.expansionSummary}
        expandIcon={<StyledExpandMoreIcon />}
        aria-controls='panel-document'
        id='panel-document'>
        <Typography className={classes.heading}>Documento</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionDetail}>
        <Grid container spacing={3}>
          {contract && contract.documentUrl ? (
            <React.Fragment>
              <Grid item xs={12} className={classes.pdfContainer}>
                <Document
                  file={{
                    url: contract.documentUrl,
                    httpHeaders: {
                      'Access-Control-Request-Method': 'GET',
                      'Access-Control-Allow-Origin': '*'
                    }
                  }}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={console.error}>
                  <Page pageNumber={pageNumber} onLoadSuccess={onPageLoadSuccess} />
                </Document>
                <div className={classes.root}>
                  <AppBar position='static'>
                    <Toolbar>
                      <div className={classes.optionsContainer}>
                        {pageNumber !== 1 && (
                          <IconButton
                            edge='start'
                            className={classes.menuButton}
                            color='inherit'
                            aria-label='arrow-back'
                            onClick={previousPage}>
                            <ArrowBackIos fontSize='large' />
                          </IconButton>
                        )}
                        <Typography display='inline' variant='h6' className={classes.pagesText}>
                          {`${pageNumber} / ${numPages}`}
                        </Typography>
                        {pageNumber !== numPages && (
                          <IconButton
                            edge='start'
                            className={classes.menuButton}
                            color='inherit'
                            aria-label='arrow-forward'
                            onClick={nextPage}>
                            <ArrowForwardIos fontSize='large' />
                          </IconButton>
                        )}
                      </div>
                    </Toolbar>
                  </AppBar>
                </div>
              </Grid>
            </React.Fragment>
          ) : (
            <UnavailableInformation />
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  expansionSummary: {
    backgroundColor: Colors.bancoldexGray
  },
  expansionDetail: {
    paddingTop: '2%',
    paddingBottom: '2%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    color: Colors.bancoldexFontColor
  },
  optionsContainer: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  menuButton: {
    margin: 'auto'
  },
  pagesText: {
    margin: 'auto'
  },
  pdfContainer: {
    flexGrow: 1
  }
}));

ContractDocument.propTypes = {
  contract: PropTypes.object
};
