import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter, Route } from 'react-router-dom';
import { esES } from '@material-ui/core/locale';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LandingPage from '../src/components/landing-page/LandingPage';
import ScrollToTop from '../src/components/common/ScrollToTop';

import Colors from './constants/Colors';

import './App.css';

const theme = createMuiTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        color: Colors.mediumGray,
        '&$completed': {
          color: Colors.primary,
        },
        '&$active': {
          color: Colors.secondary,
        },
      },
      completed: {},
    },
    MuiStepConnector: {
      active: {
        '& $line': {
          borderColor: Colors.primary,
          borderWidth: 'medium'
        },
      },
      completed: {
        '& $line': {
          borderColor: Colors.primary,
          borderWidth: 'medium'
        },
      }
    },
  },
  palette: {
    primary: {
      main: Colors.primary
    },
    secondary: {
      main: Colors.secondary
    }
  },
  status: {
    danger: 'orange'
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
  },
}, esES);

function App() {

  return (
    <div className="App">

      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop>
            <Route path="/" component={LandingPage} />
          </ScrollToTop>
        </BrowserRouter>
      </MuiThemeProvider>


    </div >
  );
}

export default App;
