import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import Colors from '../../../constants/Colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const StyledExpandMoreIcon = () => <ExpandMoreIcon style={{ color: Colors.black }} />;

export default function PersonalInformation({ item }) {
  const classes = useStyles();
  const [expansionOpened, setExpansionOpened] = useState(true);
  const toggleExpansionOpened = () => {
    setExpansionOpened(!expansionOpened);
  };

  const getPercentage = (value) => {
    if (typeof value === 'number') return `${value.toFixed(2)}%`;
    return '';
  };

  return (
    <Accordion expanded={expansionOpened} onChange={() => toggleExpansionOpened()}>
      <AccordionSummary
        className={classes.expansionSummary}
        expandIcon={<StyledExpandMoreIcon />}
        aria-controls='panel3a-content'
        id='panel3a-header'>
        <Typography className={classes.heading}>Datos personales</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionDetail}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            {item.consolidated && (
              <React.Fragment>
                <Typography variant='h6'>
                  <strong>Consistencia datos</strong>
                </Typography>
                {typeof item.consolidated.identificationPdfInputSimilarity === 'number' && (
                  <Typography>
                    <strong>Número identificación (ingresado vs Código barras):</strong>{' '}
                    {getPercentage(item.consolidated.identificationPdfInputSimilarity)}
                  </Typography>
                )}
                {typeof item.consolidated.namePdfInputSimilarity === 'number' && (
                  <Typography>
                    <strong>Nombre (ingresado vs Código barras):</strong>{' '}
                    {getPercentage(item.consolidated.namePdfInputSimilarity)}
                  </Typography>
                )}
                <br />
                {typeof item.consolidated.identificationPdfRnecSimilarity === 'number' && (
                  <Typography>
                    <strong>Número identificación (RNEC vs Código barras): </strong>
                    {getPercentage(item.consolidated.identificationPdfRnecSimilarity)}
                  </Typography>
                )}
                {typeof item.consolidated.namePdfRnecSimilarity === 'number' && (
                  <Typography>
                    <strong>Nombre (RNEC vs Código barras):</strong>{' '}
                    {getPercentage(item.consolidated.namePdfRnecSimilarity)}
                  </Typography>
                )}
                <hr />
              </React.Fragment>
            )}
          </Grid>

          <Grid item xs={12} md={6} lg={6} style={{ textAlign: 'center' }}>
            <Typography variant='h6'>
              <strong>Datos ingresados</strong>
            </Typography>
            {item.basicInfo && item.basicInfo.identification ? (
              <div className={classes.text}>
                <Typography>
                  <b>Nombre:</b> {`${item.basicInfo.name} ${item.basicInfo.lastName}`}
                </Typography>
                <Typography>
                  <b>Número de identificación:</b> {item.basicInfo.identification}
                </Typography>
              </div>
            ) : (
              <Typography>El usuario no ha ingresado sus datos básicos</Typography>
            )}
          </Grid>

          {item.dataRNEC && item.dataRNEC.primerNombre ? (
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant='h6'>
                <strong>Datos RNEC</strong>
              </Typography>
              <Typography>
                <b>Nombre:</b>{' '}
                {`${item.dataRNEC.primerNombre} ${item.dataRNEC.segundoNombre} ${item.dataRNEC.primerApellido} ${item.dataRNEC.segundoApellido}`}
              </Typography>
              <Typography>
                <b>Número de identificación:</b> {item.dataRNEC.nuip}
              </Typography>
              <Typography>
                <b>Fecha expedición documento:</b> {item.dataRNEC.fechaExpedicion}
              </Typography>
              <Typography>
                <b>Documento válido (Cédula activa):</b> {item.dataRNEC.valid ? 'Si' : 'No'}
              </Typography>
            </Grid>
          ) : (
            <Typography>No se han consultado los datos de la registraduría</Typography>
          )}
          {item.backPictureID && item.backPictureID.pdf417 && (
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant='h6'>
                <strong>Datos código de barras</strong>
              </Typography>
              <Typography>
                <b>Nombre:</b>{' '}
                {item.backPictureID.pdf417.namingCompiled ||
                  `${item.backPictureID.pdf417.lastName} ${item.backPictureID.pdf417.secondLastName} ${item.backPictureID.pdf417.name} ${item.backPictureID.pdf417.secondNames}`}
              </Typography>
              <Typography>
                <b>Número de identificación:</b> {item.backPictureID.pdf417.identification}
              </Typography>
              <Typography>
                <b>Fecha de nacimiento:</b> {item.backPictureID.pdf417.birthday}
              </Typography>
            </Grid>
          )}
          {item.frontPictureID && item.frontPictureID.ocr && item.backPictureID && item.backPictureID.ocr && false && (
            <React.Fragment>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant='h6'>Datos OCR</Typography>
                <Typography>
                  <b>Número de identificación:</b> {item.frontPictureID.ocr.identification}
                </Typography>
                <Typography>
                  <b>Fecha de nacimiento:</b> {item.backPictureID.ocr.birthday}
                </Typography>
                <Typography>
                  <b>Fecha de expedición documento:</b> {item.backPictureID.ocr.expeditionDate}
                </Typography>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  expansionSummary: {
    backgroundColor: Colors.bancoldexGray
  },
  expansionDetail: {
    paddingTop: '2%',
    paddingBottom: '2%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    color: Colors.bancoldexFontColor
  },
  text: {
    textAlign: 'left'
  }
}));

PersonalInformation.propTypes = {
  item: PropTypes.object
};
