import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import Colors from '../../../constants/Colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const StyledExpandMoreIcon = () => <ExpandMoreIcon style={{ color: Colors.black }} />;

const getDocumentString = (doc) => {
  if (doc.includes('cc')) return 'una cédula';
  if (doc.includes('license')) return 'una licencia';
};

export default function IDInformation({ item }) {
  const classes = useStyles();
  const [expansionOpened, setExpansionOpened] = useState(true);
  const toggleExpansionOpened = () => {
    setExpansionOpened(!expansionOpened);
  };

  const STRING_SIMILARITY_THRESHOLD = 55;

  return (
    <Accordion expanded={expansionOpened} onChange={() => toggleExpansionOpened()}>
      <AccordionSummary
        className={classes.expansionSummary}
        expandIcon={<StyledExpandMoreIcon />}
        aria-controls='panel3a-content'
        id='panel3a-header'>
        <Typography className={classes.heading}>Documento de identidad</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionDetail}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography>
              <b>Adelante</b>
            </Typography>
            {item.frontPictureID && item.frontPictureID.path && (
              <Typography>
                {item.basicInfo &&
                  item.basicInfo.documentType &&
                  typeof item.frontPictureID.validPicture === 'boolean' &&
                  `${
                    item.frontPictureID.validPicture
                      ? `La foto corresponde a ${getDocumentString(
                          item.basicInfo.documentType
                        )} con probabilidad de ${item.frontPictureID.model[0].probability.toFixed(2)}%`
                      : 'Se requiere revisión del documento. '
                  }`}
              </Typography>
            )}

            {item.frontPictureID.model.length === 0 && <Typography>No se ha procesado la foto.</Typography>}
            {item.consolidated &&
              (!item.consolidated.identificationOcrPdfSimilarity ||
                item.consolidated.identificationOcrPdfSimilarity < STRING_SIMILARITY_THRESHOLD) && (
                <Typography>Los datos escritos en la cédula no concuerdan con los del código de barras.</Typography>
              )}
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <b>Atrás</b>
            </Typography>
            {item.backPictureID && item.backPictureID.path && (
              <Typography>
                {item.basicInfo &&
                  item.basicInfo.documentType &&
                  typeof item.backPictureID.validPicture === 'boolean' &&
                  `${
                    item.backPictureID.validPicture
                      ? `La foto corresponde a ${getDocumentString(
                          item.basicInfo.documentType
                        )} con probabilidad de ${item.backPictureID.model[0].probability.toFixed(2)}%`
                      : 'Se requiere revisión del documento. '
                  }`}
              </Typography>
            )}

            {item.backPictureID.model.length === 0 && (
              <Typography>
                {item.backPictureID.timeout === true
                  ? 'No se pudo leer el código de barras, posible alteración al documento.'
                  : 'No se ha procesado la foto.'}
              </Typography>
            )}

            {item.consolidated &&
              ((!item.consolidated.birthdayOcrPdfSimilarity && !item.backPictureID.timeout) ||
                item.consolidated.birthdayOcrPdfSimilarity < STRING_SIMILARITY_THRESHOLD) && (
                <Typography>Los datos escritos en la cédula no concuerdan con los del código de barras.</Typography>
              )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  expansionSummary: {
    backgroundColor: Colors.bancoldexGray
  },
  expansionDetail: {
    paddingTop: '2%',
    paddingBottom: '2%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    color: Colors.bancoldexFontColor
  }
}));

IDInformation.propTypes = {
  item: PropTypes.object
};
