import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';

import Colors from '../../constants/Colors';

import MoreInfo from '../common/MoreInfo';

import { about } from '../../data/about';


const AboutUs = () => {

  useEffect(() => {
    ReactGA.pageview('/nosotros');
    ReactGA.event({
      category: 'Nosotros',
      action: 'Page: Quienes somos',
      label: 'Quienes somos',
    });
  }, []);

  const classes = useStyles(); 

  return (
    <React.Fragment>
    <div className={classes.container}>
      <Grid container spacing={2} className={classes.titleContainer}>
        <Grid item xs={12}>
          <Typography className={classes.mainTitle} variant="h4">{about.title}</Typography>
          <Typography className={classes.whiteSubtitle} variant="subtitle1">
            {about.subtitle}
          </Typography>
        </Grid>
      </Grid>
    </div>
    <div className="hero-gradient">
      <div id="wave">
        <svg  viewBox="0 0 1170 193">
          <path style={{fill: '#483fbf'}} d="M1175 131.2c0 0-81-89.4-224.3-103.4S713 72 665 97c-86 46-148 63-271 7C221.7 25.5 56 104.5-4 197.4 -4 58.7-3.3 0.3-3.3 0.3L1175 0V131.2z"></path>
        </svg>
      </div>	
    </div>
    <div className={classes.purpleContainer}>
        <Typography className={classes.mainTitle} variant="h4">Quiénes Somos</Typography>
        <Typography className={classes.whiteSubtitle} variant="subtitle1">
          {about.subtitle2}
        </Typography>
    </div>

    <div className={classes.whiteContainer}>
      <Typography className={classes.sectionTitle} variant="h5">Visión</Typography>
      {about.vision.map((item, index) => (
          <React.Fragment key={index}>
            <Typography className={classes.itemTitle} variant="h5">{item.title}</Typography>
            <Typography className={classes.subtitle} variant="subtitle1">{item.description}</Typography>
          </React.Fragment>
        ))}
    </div>
    {
          /*
      <div className={classes.whiteContainer}>
        <Typography className={classes.subtitleStart} variant="subtitle1">
          {about.description}
        </Typography>
        <Typography className={classes.subtitleStart} variant="subtitle1">
          {about.description2}
        </Typography>
        
        <Typography className={classes.sectionTitle} variant="h5">¿Cómo lo hacemos?</Typography>
        <Typography className={classes.subtitleStart} variant="subtitle1">{about.description3}</Typography>

        {about.how.map((item, index) => (
          <React.Fragment key={index}>
            <Typography className={classes.itemTitle} variant="h5">{item.title}</Typography>
            <Typography className={classes.subtitle} variant="subtitle1">{item.description}</Typography>
          </React.Fragment>
        ))}
      </div>
          */
        }

      <MoreInfo />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    margin: 'auto',
    padding: '10% 0 1%',
    backgroundColor: '#483fbf',
    position: 'relative',
    zIndex: 3,
    [theme.breakpoints.down('xs')]: {
      marginTop: '-7%',
      padding: '15% 0 5%',
    }
  },
  mainTitle: {
    color: Colors.secondary,
    fontWeight: 400,
    fontSize: '3.5em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.7em',
      textAlign: 'left'
    },
  },
  titleContainer: {
    maxWidth: '100%',
    padding: '0 15%',
    [theme.breakpoints.down('xs')]: {
      padding: '0 5%',
  },
  },
  containerItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '95%',
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: '60%',
    }
  },
  mainImage: {
    position: 'relative',
    zIndex: 10,
    margin: 'auto',
    width: '24%',
    marginBottom: '-24%',
    [theme.breakpoints.down('xs')]: {
      width: '40%',
      marginBottom: '-45%',
    },
  },
  whiteSubtitle: {
    color: Colors.white,
    margin: '4% 0',
    fontSize: '1.4em',
    fontWeight: 300,
  },
  whiteSubtitle2: {
    color: Colors.white,
    margin: '4% 5%',
    textAlign: 'center',
    fontSize: '1.4em',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
        margin: '0 0 10%',
    },
  },
  text: {
    color: Colors.gray,
    fontSize: '1.3em',
    fontWeight: 300,
  },
  whiteDescription: {
    color: Colors.white,
    fontSize: '1.35em',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3em',
    },
  },
  whiteDescription2: {
    color: Colors.white,
    fontSize: '1.6em',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em',
    },
  },
  purpleContainer: {
    position: 'relative',
    zIndex: 4,
    padding: '0 15% 5%',
    marginTop: '-4%',
    backgroundColor: Colors.primary,
    [theme.breakpoints.up('md')]: {
      marginTop: '-2%',
    },
    [theme.breakpoints.down('md')]: {
        marginTop: '-5%',
      },
    [theme.breakpoints.down('sm')]: {
        padding: '0 5% 15%',
        marginTop: '-10%',
      },
    [theme.breakpoints.down('xs')]: {
      padding: '0 5% 15%',
      marginTop: '-30%',
    },
  },
  whiteContainer: {
    padding: '5% 15%',
    backgroundColor: Colors.white,
    [theme.breakpoints.down('sm')]: {
      padding: '15% 5%',
    },
  },
  lightGrayContainer: {
    backgroundColor: Colors.lightGray,
    padding: '5% 15%',
    [theme.breakpoints.down('sm')]: {
      padding: '15% 5%',
    },
  },
  sectionTitle: {
    textAlign: 'center',
    color: Colors.primary,
    margin: '0 5% 3%',
    fontSize: '2.3em',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
    },
  },
  subtitle: {
    color: Colors.gray,
    fontWeight: 300,
    margin: '1% 0 5%',
    fontSize: '1.25em',
  },
  subtitleStart: {
    color: Colors.gray,
    margin: 'auto',
    marginBottom: '5%',
    fontSize: '1.5em',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95%',
      fontSize: '1.4em',
      textAlign: 'center',
    },
  },
  itemTitle: {
    margin: '2% 0 0',
    color: Colors.gray,
    textAlign: 'left',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em',
      textAlign: 'center',
    },
  },
}));


export default AboutUs;