import { Link, Typography } from '@material-ui/core';

import Colors from '../../constants/Colors';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const MoreInfo = ({ from, caller }) => {
  const classes = useStyles();

  const buttonClick = () => {
    ReactGA.event({
      category: 'Soluciones',
      action: 'CTA: ' + caller,
      label: caller
    });
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.sectionTitle} variant='h5'>
        ¿Quieres saber más de nuestras soluciones?
      </Typography>
      {from === 'landingPage' ? (
        <Link href='https://wa.me/573054083007' target='_blank' id='contactButton' className={classes.link}>
          <div className={classNames(classes.btnInfo, classes.color1)}>Contáctanos</div>
        </Link>
      ) : (
        <NavLink to='/demo' className={classes.primaryLink} id='contactButton'>
          <div className={classNames(classes.btnInfo, classes.color1)} onClick={buttonClick}>
            Contáctanos
          </div>
        </NavLink>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
    padding: '10% 0 10%',
    backgroundColor: '#eceeff',
    backgroundImage: 'url(/images/bg2.png)',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      padding: '15% 2% 20%',
      backgroundSize: '100% 100%',
      backgroundImage: 'url(/images/bg2-mobile.png)'
    }
  },
  sectionTitle: {
    textAlign: 'center',
    color: Colors.primary,
    fontWeight: 600,
    margin: 'auto',
    marginBottom: '5%',
    maxWidth: '30%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%'
    }
  },
  btnInfo: {
    height: '45px',
    fontWeight: 600,
    textTransform: 'uppercase',
    color: '#8EECFF',
    fontSize: '14px',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginTop: '3%',
    maxWidth: '24%',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '70%'
    },
    backgroundSize: '300% 100%',

    'moz-transition': 'all .4s ease-in-out',
    '-o-transition': 'all .4s ease-in-out',
    '-webkit-transition': 'all .4s ease-in-out',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundPosition: '100% 0',
      'moz-transition': 'all .4s ease-in-out',
      '-o-transition': 'all .4s ease-in-out',
      '-webkit-transition': 'all .4s ease-in-out',
      transition: 'all .4s ease-in-out'
    }
  },
  color1: {
    backgroundImage: `linear-gradient(to right, ${Colors.primary} 0%, #312b87 61%, ${Colors.primary} 100%)`
  },
  primaryLink: {
    textDecoration: 'none !important',
    color: Colors.secondary,
    fontWeight: 500,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '70%'
    }
  },
  link: {
    textDecoration: 'none !important'
  }
}));

export default MoreInfo;

MoreInfo.propTypes = {
  from: PropTypes.string,
  caller: PropTypes.string
};
