import { Fade, Grid, Typography } from '@material-ui/core';

import Colors from '../../constants/Colors';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

//import AnchorLink from 'react-anchor-link-smooth-scroll'
//import classNames from 'classnames';



const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={2} className={classes.titleContainer}>
        <Grid item xs={12} sm={6} className={classes.containerItem}>
          <Fade timeout={700} in={true} mountOnEnter unmountOnExit>
            <img src='./images/home.svg' alt='Computador con logo Carla Tdch' className={classes.mainImage} />
          </Fade>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.containerItem}>
          <Typography className={classes.mainTitle} variant='h2'>
            Tecnología para el mundo financiero
          </Typography>
          <Typography className={classes.graySubtitle} variant='subtitle1'>
            Moderniza tus productos y servicios fácilmente utilizando nuestras soluciones
          </Typography>
          {/*
          <Grid container spacing={1} className={classes.homeButtons}>
            <Grid item xs={10}>
            <AnchorLink href='#compare' offset='100' className={classes.primaryLink}>
              <div className={classNames(classes.btnInfo, classes.color1)}>Compara ahora</div>
            </AnchorLink>
            </Grid>
          </Grid>
          */}
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    margin: 'auto',
    paddingBottom: '5%',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-7%',
      padding: '5% 0',
      backgroundSize: '100% 100vh'
    },
    paddingTop: '2%',
    backgroundColor: Colors.white
  },
  mainTitle: {
    color: Colors.primary,
    fontWeight: 600,
    fontSize: '3.5em',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '5%',
      fontSize: '2.7em'
    }
  },
  buttonContainer: {
    margin: 'auto',
    maxWidth: '95%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
      textAlign: 'center'
    }
  },
  titleContainer: {
    maxWidth: '90%',
    margin: 'auto',

    [theme.breakpoints.up('md')]: {
      maxWidth: '70%'
    }
  },
  containerItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  mainSubtitle: {
    textAlign: 'center',
    color: Colors.primary,
    fontWeight: 500,
    margin: '5% 0',
    [theme.breakpoints.down('sm')]: {
      margin: '12% 0 7%'
    }
  },
  subtitle: {
    textAlign: 'center',
    color: Colors.primary,
    fontWeight: 500,
    margin: '3% 0'
  },
  graySubtitle: {
    margin: '4% 0',
    fontSize: '1.4em',
    color: Colors.gray,
    maxWidth: '90%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      fontSize: '1.25em'
    }
  },
  mainImage: {
    maxWidth: '100%',
    marginRight: '3%',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto'
    }
  },
  btnInfo: {
    height: '45px',
    fontWeight: 600,
    textTransform: 'uppercase',
    background: Colors.secondary,
    fontSize: '14px',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginTop: '4px',
    textAlign: 'center',
    border: 'none',
    backgroundSize: '300% 100%',
    'moz-transition': 'all .4s ease-in-out',
    '-o-transition': 'all .4s ease-in-out',
    '-webkit-transition': 'all .4s ease-in-out',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundPosition: '100% 0',
      'moz-transition': 'all .4s ease-in-out',
      '-o-transition': 'all .4s ease-in-out',
      '-webkit-transition': 'all .4s ease-in-out',
      transition: 'all .4s ease-in-out'
    }
  },
  color1: {
    backgroundImage: `linear-gradient(to right, ${Colors.secondary} 0%, #56daf5 61%, ${Colors.secondary} 100%)`
  },
  color2: {
    color: Colors.white,
    backgroundImage: 'linear-gradient(to right, #0062f5 0%, #0c46c2 51%, #0062f5 100%)'
  },
  homeButtons: {
    maxWidth: '75%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  primaryLink: {
    textDecoration: 'none',
    color: Colors.primary,
    fontWeight: 500
  },
  link: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    color: Colors.gray,
    cursor: 'pointer',
    textDecoration: 'none !important',
    padding: '1% 0',
    margin: '4% 0',
    transition: '0.3s',
    '&:hover': {
      color: Colors.yellow
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.95em'
    }
  }
}));

export default Home;
