import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import Colors from '../../../constants/Colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const StyledExpandMoreIcon = () => <ExpandMoreIcon style={{ color: Colors.black }} />;

export default function FacialInformation({ item }) {
  const classes = useStyles();
  const [expansionOpened, setExpansionOpened] = useState(true);
  const toggleExpansionOpened = () => {
    setExpansionOpened(!expansionOpened);
  };

  const getURL = (path) => {
    if (path.includes('https://')) return path;
    return `https://v2-identity-check.s3.amazonaws.com/${path}`;
  };

  return (
    <Accordion expanded={expansionOpened} onChange={() => toggleExpansionOpened()}>
      <AccordionSummary
        className={classes.expansionSummary}
        expandIcon={<StyledExpandMoreIcon />}
        aria-controls='panel3a-content'
        id='panel3a-header'>
        <Typography className={classes.heading}>Identificación facial</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionDetail}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography>
              <b>Similitud caras (prueba de vida vs documento):</b>{' '}
              {item.consolidated && item.consolidated.livenessIDSimilarity
                ? item.consolidated.livenessIDSimilarity.toFixed(2)
                : '–'}{' '}
              %
            </Typography>
            <Typography>
              <b>Video prueba de vida:</b> {item.liveness && item.liveness.approved ? 'Aprobado' : 'Pendiente'}
            </Typography>
            <hr />
          </Grid>

          <Grid item xs={12} md={6} lg={6} style={{ textAlign: 'center' }}>
            <Typography variant='h6'>
              <strong>Prueba de vida</strong>
            </Typography>
            {item.liveness && item.liveness.imagesURL[0] ? (
              item.liveness.imagesURL.map((image, index) => (
                <img
                  key={index}
                  src={getURL(image)}
                  alt={`Prueba de vida ${index}`}
                  width={item.liveness.imagesURL.length > 3 ? '31%' : '48%'}
                />
              ))
            ) : (
              <Typography>No se ha procesado el video de prueba de vida</Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6} style={{ textAlign: 'center' }}>
            <Typography variant='h6'>
              <strong>Fotos documento</strong>
            </Typography>
            {item.frontPictureID && item.frontPictureID.path ? (
              <Grid item>
                <img src={item.frontPictureID.path} alt='CC frente' width='90%' />
              </Grid>
            ) : (
              <Typography>No se ha procesado la foto de adelante</Typography>
            )}
            {item.backPictureID && item.backPictureID.path ? (
              <Grid item style={{ textAlign: 'center' }}>
                <img src={item.backPictureID.path} alt='CC atras' width='90%' />
              </Grid>
            ) : item.backPictureID && item.backPictureID.timeout ? (
              <Typography>No fue posible leer el código de barras, posible alteración al documento</Typography>
            ) : (
              <Typography>No se ha procesado la foto de atrás</Typography>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  expansionSummary: {
    backgroundColor: Colors.bancoldexGray
  },
  expansionDetail: {
    paddingTop: '2%',
    paddingBottom: '2%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    color: Colors.bancoldexFontColor
  }
}));

FacialInformation.propTypes = {
  item: PropTypes.object
};
