import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { makeStyles } from '@material-ui/core/styles';

import Colors from '../../constants/Colors';

import { faq } from '../../data/about';


const FAQ = () => {

  useEffect(() => {
    ReactGA.pageview('/preguntas-frecuentes');
    ReactGA.event({
      category: 'Nosotros',
      action: 'Page: FAQ',
      label: 'Quienes somos',
    });
  }, []);
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Grid container spacing={2} className={classes.titleContainer}>
          <Grid item xs={12} sm={6} className={classes.containerItem}>
            <Typography className={classes.mainTitle} variant="h4">Preguntas Frecuentes</Typography>
          </Grid>
        </Grid>
      </div>
      <div className={classes.whiteContainer}>
        {faq.map((question, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>{question.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
            {question.description &&
              <Typography>
                  {question.description}
              </Typography>
            }
            {question.description2 &&
              <React.Fragment>
                <br/>
                <Typography >
                  {question.description2}
              </Typography>
              </React.Fragment>
            }
            {question.description3 &&
              <React.Fragment>
                <br/>
                <Typography >
                  {question.description3}
              </Typography>
              </React.Fragment>
            }
            {question.list &&
              <List >
                {question.list.map((item, index) => (
                  <ListItem key={index}>
                  <ListItemText
                    primary={item.split(":")[0]}
                    secondary={item.split(":")[1]}
                  />
                  </ListItem>
                ))}
            </List>
            }

            </AccordionDetails>
          </Accordion>
        ))}

      </div>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    margin: 'auto',
    padding: '5% 0',
    backgroundColor: Colors.primary,
    [theme.breakpoints.down('xs')]: {
      marginTop: '-7%',
      padding: '10% 0',
    }
  },
  heading: {
    fontWeight: 400,
    fontSize: '1.4em'
  },
  mainTitle: {
    color: Colors.secondary,
    fontWeight: 400,
    fontSize: '3.5em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.7em',
    },
  },
  titleContainer: {
    margin: 'auto',
    maxWidth: '90%',
    textAlign: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '70%',
    },
  },
  containerItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  whiteSubtitle: {
    color: Colors.white,
    margin: '4% 0',
    fontSize: '1.4em',
    maxWidth: '90%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  btnInfo: {
    height: '45px',
    fontWeight: 600,
    textTransform: 'uppercase',
    background: Colors.secondary,
    fontSize: '14px',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginTop: '4px',
    transition: '0.2s',
    '&:hover': {
      background: Colors.lightGray
    }
  },
  homeButtons: {
    marginTop: '10%',
    maxWidth: '70%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  primaryLink: {
    textDecoration: 'none',
    color: Colors.primary,
    fontWeight: 500
  },
  blueButton: {
    height: '85%',
    transition: '0.2s',
    '&:hover': {
      height: '90%'
    }
  },
  whiteContainer: {
    padding: '5% 12%',
    backgroundColor: Colors.white,
    [theme.breakpoints.down('sm')]: {
      padding: '15% 5%',
    },
  },
  sectionTitle: {
    textAlign: 'center',
    color: Colors.primary,
    fontWeight: 600,
    margin: '0 5% 5%'
  },
  subtitle: {
    color: Colors.gray,
    fontWeight: 300,
    margin: '3% 0'
  },
  itemTitle: {
    margin: '2% 0',
    color: Colors.gray,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em',
    },
  },
  image: {
    maxWidth: '40%',
  },
  imageGrid: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  }
}));


export default FAQ;
