import { CheckCircle } from '@material-ui/icons';
import Colors from '../../constants/Colors';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const DemoComplete = ({ location }) => {
  const classes = useStyles();

  if (!location.state) {
    return (
      <Redirect
        to={{
          pathname: '/'
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <div className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography className={classes.mainTitle}>¡Hemos recibido tu solicitud!</Typography>
          <Typography className={classes.body}>
            Gracias por ponerte en contacto con nosotros {location.state.name}! Te contactaremos lo más pronto posible.
          </Typography>
          <CheckCircle className={classes.checkMark} />
        </Paper>
      </div>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '90vh'
  },
  mainTitle: {
    color: Colors.gray,
    fontSize: '3em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.3em'
    },
    textAlign: 'center',
    marginBottom: '2%'
  },
  body: {
    fontSize: '1.35em',
    textAlign: 'center'
  },
  layout: {
    minHeight: '68vh',
    width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(900 + theme.spacing(1) * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-7%',
      marginBottom: '3%'
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    padding: '10%',
    overflow: 'auto',
    minHeight: '40vh'
  },
  checkMark: {
    paddingTop: '3%',
    color: 'green',
    marginLeft: '47%',
    fontSize: '90px'
  }
}));

export default DemoComplete;

DemoComplete.propTypes = {
  location: PropTypes.object
};
