import { AccessTime, Check as CheckIcon, ReportProblem } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary, Fab, Grid, Tooltip, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import Colors from '../../../constants/Colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const StyledExpandMoreIcon = () => <ExpandMoreIcon style={{ color: Colors.black }} />;

const FACEMATCH_THRESHOLD = 70;
const STRING_SIMILARITY_THRESHOLD = 55;

const getDocumentString = (doc) => {
  if (doc.includes('cc')) return 'una cédula';
  if (doc.includes('license')) return 'una licencia';
};

const getStatus = (row) => {
  if (typeof row.passed === 'boolean') {
    if (row.passed) return 'Aprobado';
    else return 'Requiere revisión manual';
  } else if (row.liveness && row.liveness.approved) return 'Video prueba vida aprobado';
  else if (row.backPictureID && row.backPictureID.path) return 'Foto ID atrás procesada';
  else if (row.frontPictureID && row.frontPictureID.path) return 'Foto ID adelante procesada';
  else if (row.basicInfo && row.basicInfo.name) return 'Datos personales procesados';
  else return 'Link abierto';
};

const getTooltip = (text, status) => (
  <Tooltip title={text} aria-label={text}>
    <Fab
      style={
        status === 'passed'
          ? { backgroundColor: '#25d353', margin: '5%' }
          : status === 'failed'
          ? { backgroundColor: '#ed850e', margin: '5%' }
          : { backgroundColor: Colors.lightGray, margin: '5%' }
      }>
      {status === 'passed' ? <CheckIcon /> : status === 'failed' ? <ReportProblem /> : <AccessTime />}
    </Fab>
  </Tooltip>
);

const getFaceStatus = (entry) => {
  let text = '';
  let status = '';

  if (!entry.frontPictureID.path) text = 'Todavía no se ha procesado la foto delantera del documento';
  else if (entry.backPictureID && entry.backPictureID.timeout === true) {
    text = 'No fue posible leer el código de barras, posible alteración al documento';
    status = 'failed';
  } else if (!entry.backPictureID.path) text = 'Todavía no se ha procesado la foto trasera del documento';
  else if (!entry.liveness.imagesURL) text = 'Todavía no se ha procesado el video de prueba de vida';
  else if (!entry.consolidated) text = 'Se está procesando el resultado';
  else if (entry.consolidated && entry.consolidated.livenessIDSimilarity < FACEMATCH_THRESHOLD) {
    text = 'La cara del documento no concuerda con la de la prueba de vida';
    status = 'failed';
  } else if (entry.consolidated && entry.consolidated.livenessIDSimilarity >= FACEMATCH_THRESHOLD) {
    text = 'Prueba exitosa';
    status = 'passed';
  }

  return getTooltip(text, status);
};

const getBasicInfoStatus = (entry) => {
  let text = '';
  let status = '';

  if (!entry.basicInfo || !entry.basicInfo.name) text = 'Todavía no se han ingresado los datos básicos';
  else if (entry.backPictureID && entry.backPictureID.timeout === true) {
    text = 'No fue posible leer el código de barras, posible alteración al documento';
    status = 'failed';
  } else if (!entry.backPictureID.path) text = 'Todavía no se ha procesado la foto trasera del documento';
  else if (!entry.dataRNEC) text = 'Todavía no se ha consultado registraduría';
  else if (entry.dataRNEC && entry.dataRNEC.valid === false) {
    text = 'La cédula ingresada no es válida (RNEC)';
    status = 'failed';
  } else if (!entry.consolidated) text = 'Se está procesando el resultado';
  else if (
    entry.consolidated &&
    (entry.consolidated.identificationPdfInputSimilarity < STRING_SIMILARITY_THRESHOLD ||
      entry.consolidated.identificationPdfRnecSimilarity < STRING_SIMILARITY_THRESHOLD ||
      entry.consolidated.namePdfInputSimilarity < STRING_SIMILARITY_THRESHOLD ||
      entry.consolidated.namePdfRnecSimilarity < STRING_SIMILARITY_THRESHOLD)
  ) {
    text = 'Hay errores en la consistencia de datos';
    status = 'failed';
  } else {
    text = 'Prueba exitosa';
    status = 'passed';
  }

  return getTooltip(text, status);
};

const getIDStatus = (entry) => {
  let text = '';
  let status = '';

  if (!entry.frontPictureID.path) text = 'Todavía no se ha procesado la foto delantera del documento';
  else if (entry.backPictureID && entry.backPictureID.timeout === true) {
    text = 'No fue posible leer el código de barras, posible alteración al documento';
    status = 'failed';
  } else if (!entry.backPictureID.path) text = 'Todavía no se ha procesado la foto trasera del documento';
  else if (
    entry.basicInfo &&
    entry.basicInfo.documentType &&
    entry.backPictureID &&
    entry.backPictureID.validPicture === false
  ) {
    text = `No fue posible verificar que la foto de atrás corresponde a ${getDocumentString(
      entry.basicInfo.documentType
    )}`;
    status = 'failed';
  } else if (
    entry.basicInfo &&
    entry.basicInfo.documentType &&
    entry.frontPictureID &&
    entry.frontPictureID.validPicture === false
  ) {
    text = `No fue posible verificar que la foto de adelante corresponde a ${getDocumentString(
      entry.basicInfo.documentType
    )}`;
    status = 'failed';
  } else if (
    entry.consolidated &&
    (!entry.consolidated.identificationOcrPdfSimilarity ||
      entry.consolidated.identificationOcrPdfSimilarity < STRING_SIMILARITY_THRESHOLD ||
      !entry.consolidated.birthdayOcrPdfSimilarity ||
      entry.consolidated.birthdayOcrPdfSimilarity < STRING_SIMILARITY_THRESHOLD)
  ) {
    text = 'Los datos escritos en la cédula no concuerdan con los del código de barras';
    status = 'failed';
  } else if (
    entry.frontPictureID &&
    entry.frontPictureID.validPicture &&
    entry.backPictureID &&
    entry.backPictureID.validPicture &&
    entry.backPictureID &&
    entry.backPictureID.pdf417
  ) {
    text = 'Prueba exitosa';
    status = 'passed';
  }

  return getTooltip(text, status);
};

export default function Summary({ item }) {
  const classes = useStyles();
  const [expansionOpened, setExpansionOpened] = useState(true);
  const toggleExpansionOpened = () => {
    setExpansionOpened(!expansionOpened);
  };

  console.log(item);
  return (
    <Accordion expanded={expansionOpened} onChange={() => toggleExpansionOpened()}>
      <AccordionSummary
        className={classes.expansionSummary}
        expandIcon={<StyledExpandMoreIcon />}
        aria-controls='panel3a-content'
        id='panel3a-header'>
        <Typography className={classes.heading}>Resumen</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionDetail}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant='h6'>
              <strong>Estado</strong>
            </Typography>
            <Typography>{getStatus(item)}</Typography>
            <hr />
          </Grid>

          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                {getFaceStatus(item)}
              </Grid>
              <Grid item xs={9} className={classes.gridItem}>
                <Typography variant='subtitle1'>Identificación facial</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                {getBasicInfoStatus(item)}
              </Grid>
              <Grid item xs={9} className={classes.gridItem}>
                <Typography variant='subtitle1'>Datos personales</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                {getIDStatus(item)}
              </Grid>
              <Grid item xs={9} className={classes.gridItem}>
                <Typography variant='subtitle1'>Documento de identidad</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  expansionSummary: {
    backgroundColor: Colors.bancoldexGray
  },
  expansionDetail: {
    paddingTop: '2%',
    paddingBottom: '2%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    color: Colors.bancoldexFontColor
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center'
  }
}));

Summary.propTypes = {
  item: PropTypes.object
};
