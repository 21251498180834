import React, { useState } from 'react';
import { validateAlphabeticInput, validateEmailInput, validatePhoneInput } from '../../controllers/validators';

import Button from '@material-ui/core/Button';
import { Chip } from '@material-ui/core';
import Colors from '../../constants/Colors';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const Contact = () => {
  const classes = useStyles();
  const [contactInfo, setContactInfo] = useState({ data: {}, errors: {} });
  const [open, setOpen] = React.useState(false);

  const handleSend = () => {
    setOpen(true);

    fetch(`${process.env.REACT_APP_SERVER_HOST}/funnel/contact`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: { ...contactInfo.data }
      })
    })
      .then(() => {
        contactInfo.data = {};
        setContactInfo({ ...contactInfo });
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = (name, value, error) => {
    contactInfo.data[name] = value;
    contactInfo.errors[name] = error;
    setContactInfo({ ...contactInfo });
  };

  const handleSelect = (event) => {
    handleChange(event.target.name, event.target.value, false);
  };

  const handleEmail = (event) => {
    const error = !validateEmailInput(event.target.value);
    handleChange(event.target.name, event.target.value, error);
  };

  const handlePhone = (event) => {
    const error = !validatePhoneInput(event.target.value);
    handleChange(event.target.name, event.target.value, error);
  };

  const handleName = (event) => {
    const error = !validateAlphabeticInput(event.target.value);
    handleChange(event.target.name, event.target.value, error);
  };

  const handleContent = (event) => {
    const error = event.target.value.length === 800;
    if (event.target.value.length <= 800) handleChange(event.target.name, event.target.value, error);
  };

  const validateForm = () => {
    const data = contactInfo.data;
    const errors = contactInfo.errors;
    return (
      data.name &&
      (data.phone || data.email) &&
      data.content &&
      !(errors.name || errors.email || errors.phone || errors.content)
    );
  };

  return (
    <React.Fragment>
      <div className={classes.layout}>
        <Paper className={classes.paper}>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity='success'>
              ¡Hemos recibido tu mensaje!
            </Alert>
          </Snackbar>

          <div className={classes.paper}>
            <Typography className={classes.mainTitle}>Contáctanos</Typography>
            <form className={classes.form} noValidate>
              <TextField
                required
                variant='outlined'
                margin='dense'
                name='name'
                label='Cómo te llamas?'
                fullWidth
                autoComplete='name'
                value={contactInfo.data.name || ''}
                error={contactInfo.errors.name}
                helperText={contactInfo.errors.name ? 'Revisa que tu nombre solo tenga letras' : null}
                onChange={handleName}
              />
              <FormControl variant='outlined' fullWidth margin='dense'>
                <InputLabel id='demo-simple-select-outlined-label'>¿Cómo te contactamos?</InputLabel>
                <Select
                  fullWidth
                  labelId='demo-simple-select-outlined-label'
                  value={contactInfo.data.communication || ''}
                  name='communication'
                  onChange={handleSelect}
                  label='¿Cómo te contactamos?'>
                  <MenuItem value={'email'}>Correo</MenuItem>
                  <MenuItem value={'phone'}>Celular</MenuItem>
                </Select>
              </FormControl>
              {contactInfo.data.communication && contactInfo.data.communication === 'email' && (
                <TextField
                  variant='outlined'
                  margin='dense'
                  required
                  fullWidth
                  label='Correo electrónico'
                  name='email'
                  autoComplete='email'
                  error={contactInfo.errors.email}
                  helperText={contactInfo.errors.email ? 'Ingresa un correo válido' : null}
                  onChange={handleEmail}
                  value={contactInfo.data.email || ''}
                />
              )}
              {contactInfo.data.communication && contactInfo.data.communication === 'phone' && (
                <TextField
                  variant='outlined'
                  margin='dense'
                  required
                  id='phone'
                  name='phone'
                  type='tel'
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  label='Número de celular'
                  fullWidth
                  autoComplete='tel'
                  error={contactInfo.errors.phone}
                  helperText={contactInfo.errors.phone ? 'Ingresa un número válido' : null}
                  onChange={handlePhone}
                  value={contactInfo.data.phone || ''}
                  InputProps={{
                    startAdornment: <Chip label='+57' color='secondary' className={classes.indicator} />
                  }}
                />
              )}

              <TextField
                fullWidth
                variant='outlined'
                margin='dense'
                label='Mensaje'
                multiline
                rows='9'
                name='content'
                error={contactInfo.errors.content}
                helperText={contactInfo.errors.content ? 'Ingresa un mensaje de máximo 800 caracteres' : null}
                onChange={handleContent}
                value={contactInfo.data.content || ''}
              />
              {validateForm() && (
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={handleSend}
                  id='sendButton'>
                  Enviar
                </Button>
              )}
            </form>
            <Typography>
              O escríbenos directamente por{' '}
              <Link id='linkMailFM' href='mailto:info@financialomejor.com'>
                correo
              </Link>{' '}
              o{' '}
              <Link id='linkWAFM' target='_blank' href='https://wa.me/573054083007'>
                WhatsApp
              </Link>
            </Typography>
          </div>
        </Paper>
      </div>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '90vh'
  },
  mainTitle: {
    color: Colors.primary,
    fontSize: '3em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.3em'
    },
    textAlign: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  indicator: {
    marginRight: 10
  },
  layout: {
    minHeight: '72vh',
    width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(900 + theme.spacing(1) * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-7%',
      marginBottom: '3%'
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(2),
    overflow: 'auto'
  }
}));

export default Contact;
