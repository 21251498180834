import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import Colors from '../../../constants/Colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LabelField from '../../common/LabelField';
import PropTypes from 'prop-types';
import UnavailableInformation from '../../common/UnavailableInformation';
import { makeStyles } from '@material-ui/core/styles';

const StyledExpandMoreIcon = () => <ExpandMoreIcon style={{ color: Colors.black }} />;

export default function ContractInformation({ contract }) {
  const classes = useStyles();
  const [expansionOpened, setExpansionOpened] = useState(true);
  const toggleExpansionOpened = () => {
    setExpansionOpened(!expansionOpened);
  };

  return (
    <Accordion expanded={expansionOpened} onChange={() => toggleExpansionOpened()}>
      <AccordionSummary
        className={classes.expansionSummary}
        expandIcon={<StyledExpandMoreIcon />}
        aria-controls='panel3a-content'
        id='panel3a-header'>
        <Typography className={classes.heading}>Información del contrato</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionDetail}>
        <Grid container spacing={3}>
          {contract ? (
            <React.Fragment>
              {contract.name && (
                <Grid item xs={12} sm={6}>
                  <LabelField fullWidth defaultValue={contract.name} label='Nombre del contrato' disabled />
                </Grid>
              )}
              {contract.documentUrl && (
                <Grid item xs={12} sm={6}>
                  <LabelField fullWidth defaultValue={contract.documentUrl} label='URL del contrato' disabled />
                </Grid>
              )}
              {contract.validationUrl && (
                <Grid item xs={12} sm={6}>
                  <LabelField fullWidth defaultValue={contract.validationUrl} label='URL de validación' disabled />
                </Grid>
              )}
              {contract.documentUrl && (
                <Grid item xs={12} sm={6}>
                  <LabelField fullWidth defaultValue={contract.validationId ? 'SI' : 'NO'} label='Firmado' disabled />
                </Grid>
              )}
            </React.Fragment>
          ) : (
            <UnavailableInformation />
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles((theme) => ({
  expansionSummary: {
    backgroundColor: Colors.bancoldexGray
  },
  expansionDetail: {
    paddingTop: '2%',
    paddingBottom: '2%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    color: Colors.bancoldexFontColor
  }
}));

ContractInformation.propTypes = {
  contract: PropTypes.object
};
