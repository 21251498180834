import { Backdrop, Button, CircularProgress, Snackbar, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Colors from '../../../constants/Colors';
import ContractDocument from '../contractDetail/ContractDocument';
import ContractInformation from '../contractDetail/ContractInformation';
import Dialog from '@material-ui/core/Dialog';
import FacialInformation from './FacialInformation';
import IDInformation from './IDInformation';
import IconButton from '@material-ui/core/IconButton';
import MuiAlert from '@material-ui/lab/Alert';
import PersonalInformation from './PersonalInformation';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import Summary from './Summary';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function CarlaDetail({ item, open, setOpen }) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const [openError, setOpenError] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
    setErrorMessage('');
  };

  return (
    <div>
      {item ? (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <Backdrop className={classes.backdrop} open={false}>
            <CircularProgress color='inherit' />
          </Backdrop>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
                <CloseIcon />
              </IconButton>
              <Typography variant='h6' className={classes.title}>
                <strong>ID: </strong>
                {item._id}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className={classes.expansionContainer}>
            <ContractInformation contract={item} />
            <ContractDocument contract={item} />
            <Summary item={item} />
            <FacialInformation item={item} />
            <PersonalInformation item={item} />
            <IDInformation item={item} />

            <div className={classes.buttonContainer}>
              <Button
                onClick={handleClose}
                variant='contained'
                style={{ backgroundColor: Colors.primary, color: Colors.white }}>
                VOLVER
              </Button>
            </div>
          </div>
          <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
            <MuiAlert onClose={handleCloseError} severity='error'>
              {errorMessage}
            </MuiAlert>
          </Snackbar>
        </Dialog>
      ) : null}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  expansionContainer: {
    padding: '2%'
  },
  buttonContainer: {
    width: '100%',
    textAlign: 'right',
    paddingTop: '1%'
  }
}));

CarlaDetail.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};
