import Colors from '../../constants/Colors';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const SecondaryButtonLink = ({ href, text, caller }) => {
  const classes = useStyles();

  const buttonClick = () => {
    ReactGA.event({
      category: 'Soluciones',
      action: 'CTA: ' + caller,
      label: caller
    });
    window.open(href, '_blank');
  };

  return (
    <div className={classNames(classes.btnInfo, classes.color1)} onClick={buttonClick}>
      {text}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  primaryLink: {
    textDecoration: 'none'
  },
  btnInfo: {
    height: '45px',
    fontWeight: 600,
    textTransform: 'uppercase',
    background: Colors.secondary,
    fontSize: '1.1em',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginTop: '3%',
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '70%'
    },
    backgroundSize: '300% 100%',

    'moz-transition': 'all .4s ease-in-out',
    '-o-transition': 'all .4s ease-in-out',
    '-webkit-transition': 'all .4s ease-in-out',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundPosition: '100% 0',
      'moz-transition': 'all .4s ease-in-out',
      '-o-transition': 'all .4s ease-in-out',
      '-webkit-transition': 'all .4s ease-in-out',
      transition: 'all .4s ease-in-out'
    }
  },
  color1: {
    color: Colors.primary,
    backgroundImage: `linear-gradient(to right, ${Colors.secondary} 0%, #56daf5 61%, ${Colors.secondary} 100%)`
  }
}));

SecondaryButtonLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  caller: PropTypes.string
};

export default SecondaryButtonLink;
