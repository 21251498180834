import { Collapse, Link, Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Alert from '@material-ui/lab/Alert';
import CarlaDetail from './carlaDetail/CarlaDetail';
import Colors from '../../constants/Colors';
import { DocumentForm } from '../common/DocumentForm';
import Grid from '@material-ui/core/Grid';
import MoreInfo from '../common/MoreInfo';
import ReactGA from 'react-ga';
import SecondaryButton from '../common/SecondaryButton';
import SecondaryButtonLink from '../common/SecondaryButtonLink';
import Slider from 'react-slick';
import Typography from '@material-ui/core/Typography';
import { digitalDocumentation as dd } from '../../data/solutions';
import { findUserContract } from '../api/form';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';

const DigitalDocumentation = () => {
  const classes = useStyles();
  const [search, setSearch] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    ReactGA.pageview('/digital-signature');
    ReactGA.event({
      category: 'Soluciones',
      action: 'Page: Digital-Documentation',
      label: 'Digital-Documentation'
    });
  }, []);

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });

  const settings = {
    className: 'center',
    centerMode: isMobile ? false : true,
    infinite: true,
    dots: true,
    autoplay: true,
    centerPadding: '0px',
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplaySpeed: 4500
  };

  const handleSearch = async () => {
    const result = await findUserContract(
      documentData.documentId,
      documentData.identification,
      documentData.documentType
    );
    if (result.data) {
      handleOpenDetail(result.data);
    } else setOpen(true);
  };

  const handleOpenDetail = (detailContract) => {
    setSelectedItem(detailContract);
    setDetailOpen(true);
  };

  const handleDetail = (isOpen) => {
    if (!isOpen) setSelectedItem(null);
    setDetailOpen(isOpen);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='info'>
          No hay documentos asociados a los datos ingresados
        </Alert>
      </Snackbar>
      <CarlaDetail open={detailOpen} setOpen={handleDetail} item={selectedItem} />
      <div className={classes.container}>
        <Grid container spacing={2} className={classes.titleContainer}>
          <Grid item xs={12} className={classes.containerItem}>
            <Typography className={classes.mainTitle} variant='h4'>
              <strong>Digital Signature</strong>
              <br />
              Colateral Digital
            </Typography>
            <Typography className={classes.whiteSubtitle} variant='subtitle1'>
              {dd.subtitle}
            </Typography>
            <Grid container spacing={2} className={classes.homeButtons}>
              <Grid item xs={12}>
                <SecondaryButtonLink
                  text='Comienza ahora'
                  href='https://dashboard.financialomejor.com'
                  caller='digital-authentication'
                  id='ctaButton'
                />
              </Grid>
              <Grid item xs={12}>
                <SecondaryButton text='Solicita tu demo' path='demo' caller='digital-signature' id='ctaButton' />
              </Grid>
              <Grid item xs={12}>
                <Collapse in={search}>
                  <DocumentForm
                    data={documentData}
                    setData={setDocumentData}
                    onHide={() => setSearch(false)}
                    onSearch={handleSearch}
                  />
                </Collapse>
                {!search && (
                  <Link className={classes.secondaryLink} onClick={() => setSearch(true)}>
                    <Typography>Revisar mi documento</Typography>
                  </Link>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.containerItem}>
            <img
              src={'products/digital-signature.svg'}
              alt='Logo documentación digital'
              className={classes.mainImage}
            />
          </Grid>
        </Grid>
      </div>
      <div className='hero-gradient'>
        <div id='wave'>
          <svg viewBox='0 0 1170 193'>
            <path
              style={{ fill: '#483fbf' }}
              d='M1175 131.2c0 0-81-89.4-224.3-103.4S713 72 665 97c-86 46-148 63-271 7C221.7 25.5 56 104.5-4 197.4 -4 58.7-3.3 0.3-3.3 0.3L1175 0V131.2z'></path>
          </svg>
        </div>
      </div>
      <div className={classes.purpleContainer}>
        <Grid container className={classes.infoContainer} spacing={4} alignItems='center' justify='center'>
          <Grid item xs={12} md={6}>
            <Typography className={classes.whiteDescription} variant='h5'>
              {dd.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.whiteDescription2} variant='h5'>
              <strong>{dd.description2}</strong>
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className={classes.whiteContainer}>
        <Typography className={classes.benefitsTitle} variant='h4'>
          Características
        </Typography>
        <Grid container spacing={4} justify='center'>
          {dd.characteristics.map((char, index) => (
            <Grid item xs={12} md={4} key={index}>
              <img src={dd.characteristicImages[index]} alt={`Imágen ${index}`} className={classes.benefitImages} />
              <Typography className={classes.text} variant='body1'>
                {char}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={classes.containerTitle}>
        <Typography className={classes.benefitsTitle} variant='h4'>
          Beneficios
        </Typography>
        <hr style={{ margin: '5% 0 0' }} />
      </div>
      <div className={classes.containerCharacteristics}>
        <div style={{ overflow: 'hidden' }}>
          <div className={classes.sliderContainer}>
            <Slider {...settings}>
              {dd.benefits.map((c, index) => (
                <div key={index} className={classes.slideWrapper}>
                  <img className={classes.slideImage} src={dd.images[index]} alt={`Imágen ${index}`} />
                  <Typography variant='subtitle1' className={classes.whiteText}>
                    {c}
                  </Typography>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <MoreInfo caller={'Digital-Documentation'} />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    margin: 'auto',
    padding: '10% 0 1%',
    backgroundColor: '#483fbf',
    position: 'relative',
    zIndex: 3,
    [theme.breakpoints.down('xs')]: {
      marginTop: '-7%',
      padding: '15% 0'
    }
  },
  mainTitle: {
    textAlign: 'center',
    color: Colors.secondary,
    fontWeight: 400,
    fontSize: '3.5em',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20%',
      fontSize: '2.7em'
    }
  },
  titleContainer: {
    margin: 'auto',
    maxWidth: '90%',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '60%'
    }
  },
  containerItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  subtitle: {
    textAlign: 'center',
    color: Colors.yellow,
    fontWeight: 500,
    margin: '3% 0'
  },
  mainImage: {
    position: 'relative',
    zIndex: 10,
    margin: 'auto',
    width: '24%',
    marginBottom: '-24%',
    [theme.breakpoints.down('xs')]: {
      width: '40%',
      marginBottom: '-45%'
    }
  },
  homeButtons: {
    maxWidth: '50%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  whiteSubtitle: {
    color: Colors.white,
    margin: '4% 0',
    textAlign: 'center',
    fontSize: '1.4em',
    maxWidth: '75%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  text: {
    color: Colors.gray,
    fontSize: '1.25em',
    fontWeight: 300
  },
  whiteDescription: {
    color: Colors.white,
    fontSize: '1.45em',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25em'
    }
  },
  whiteDescription2: {
    color: Colors.white,
    fontSize: '1.6em',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em'
    }
  },
  sectionTitle: {
    textAlign: 'center',
    color: Colors.primary,
    fontWeight: 600,
    margin: '0 5% 5%'
  },
  whiteContainer: {
    padding: '5% 15%',
    backgroundColor: Colors.white,
    [theme.breakpoints.down('sm')]: {
      padding: '15% 5%'
    }
  },
  purpleContainer: {
    position: 'relative',
    zIndex: 4,
    padding: '0% 15% 5%',
    backgroundColor: Colors.primary,
    [theme.breakpoints.down('sm')]: {
      padding: '5% 5% 15%',
      marginTop: '-15%'
    }
  },
  benefitsTitle: {
    margin: '5% 0 4%',
    color: Colors.primary,
    fontSize: '2.3em',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9em'
    }
  },
  slideImage: {
    width: '20%',
    margin: 'auto',
    padding: '5% 0'
  },
  sliderContainer: {
    display: 'block',
    margin: '20px auto'
  },
  slideWrapper: {
    overflow: 'visible',
    backgroundColor: Colors.primary,
    padding: '10%',
    width: '300px',
    borderRadius: '40px',
    marginTop: '6vh',
    marginBottom: '5%',
    borderColor: Colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginBottom: '2%'
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '35vh'
    }
  },
  containerTitle: {
    backgroundColor: 'white',
    padding: '1% 18% 5%',
    [theme.breakpoints.down('sm')]: {
      padding: '3% 10% 0'
    }
  },
  containerCharacteristics: {
    backgroundColor: 'white',
    paddingBottom: '5%',
    [theme.breakpoints.down('sm')]: {
      padding: '5% 2%'
    }
  },
  whiteText: {
    color: Colors.white,
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25em'
    }
  },
  secondaryLink: {
    color: Colors.white,
    textDecoration: 'underline',
    cursor: 'pointer',
    textAlign: 'center'
  },
  benefitImages: {
    display: 'block',
    margin: 'auto',
    height: '12vh',
    marginBottom: '7%'
  }
}));

export default DigitalDocumentation;
