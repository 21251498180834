import AnchorLink from 'react-anchor-link-smooth-scroll';
import Colors from '../../constants/Colors';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const ProductIntro = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={2} className={classes.titleContainer}>
        <Grid item xs={12} sm={6} className={classes.containerItem}>
          <Typography className={classes.mainTitle} variant='h4'>
            Conecta el futuro a tus servicios financieros
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.containerItem}>
          <Typography className={classes.whiteSubtitle} variant='subtitle1'>
            Aprovecha los beneficios de contar con lo último en tecnología para mejorar tus procesos y la experiencia de
            tus clientes
          </Typography>
          <AnchorLink href='#solutions' offset='100' className={classes.primaryLink}>
            <div className={classes.btnInfo}>Conocer más</div>
          </AnchorLink>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    margin: 'auto',
    padding: '10% 0',
    backgroundColor: Colors.primary,
    backgroundImage: 'url(/images/bg1.png)',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: 'url(/images/bg1-mobile.png)'
    }
  },
  mainTitle: {
    color: Colors.secondary,
    fontSize: '2.7em',
    fontWeight: 700,
    marginBottom: '5%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.2em'
    }
  },
  allyContainer: {
    overflow: 'hidden',
    backgroundColor: Colors.lightGray,
    borderRadius: '55px',
    position: 'relative',
    zIndex: 3,
    height: '12vh',
    margin: 'auto',
    marginTop: '8%',
    maxWidth: '95%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '70%'
    }
  },
  titleContainer: {
    margin: 'auto',
    maxWidth: '90%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60%'
    }
  },
  slider: {
    height: '100%'
  },
  carouselImage: {
    margin: 'auto',
    width: '80%',
    [theme.breakpoints.up('lg')]: {
      width: '74%'
    }
  },
  containerItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  whiteSubtitle: {
    color: Colors.white,
    lineHeight: 2,
    fontSize: '1.4em',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25em',
      maxWidth: '100%'
    }
  },
  btnInfo: {
    height: '45px',
    fontWeight: 600,
    textTransform: 'uppercase',
    backgroundImage: `linear-gradient(to right, ${Colors.secondary} 0%, #56daf5 61%, ${Colors.secondary} 100%)`,
    fontSize: '14px',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginTop: '15px',
    backgroundSize: '300% 100%',

    'moz-transition': 'all .4s ease-in-out',
    '-o-transition': 'all .4s ease-in-out',
    '-webkit-transition': 'all .4s ease-in-out',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundPosition: '100% 0',
      'moz-transition': 'all .4s ease-in-out',
      '-o-transition': 'all .4s ease-in-out',
      '-webkit-transition': 'all .4s ease-in-out',
      transition: 'all .4s ease-in-out'
    }
  },
  primaryLink: {
    textDecoration: 'none',
    color: Colors.primary,
    fontWeight: 500,
    width: '77%',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10%',
      margin: 'auto'
    }
  }
}));

export default ProductIntro;
