export const list = [
    {
        name: 'Fintech as a Service',
        link: 'faas',
        description: 'Obtén el mundo crediticio sin necesidad de desarrollos, APIs ni conexiones.',
        descriptionLong: 'Obtén el mundo crediticio sin necesidad de desarrollos, APIs ni conexiones. Obtén todo lo que necesitas para prestar dinero y colocar productos de crédito en una plataforma auto-gestionada y totalmente personalizable a tu medida.',
        moreInfo: 'Digitaliza tu proceso de onboarding y optimiza tu colocación en productos de crédito en una plataforma auto-gestionada y totalmente personalizable. bajo una sola conexión.'
    },
    {
        name: 'Digital ID Verification',
        link: 'digital-verification',
        description: 'Identifica a tus clientes en todos tus procesos: onboarding, login, checkout, y más.',
        descriptionLong: 'Identifica a tus clientes en todos tus procesos: onboarding, login, checkout, firma de contratos u otros documentos',
        moreInfo: 'Proceso de autenticación y validacion de identidad 100% digital utilizando modelos de comparacion para el reconocimiento, eliminando el riesgo de suplantación.'
    },
    {
        name: 'Digital Signature',
        link: 'digital-signature',
        description: 'Elimina la necesidad de firmar documentos, plantillas, pagarés y contratos físicos.',
        descriptionLong: 'Elimina la necesidad de tener documentos, plantillas, pagarés y contratos físicos o que tus clientes tengan que firmar presencialmente la documentación requerida.',
        moreInfo: 'Logra firmar electrónicamente por medio de nuestro producto de autenticación digital, el cual permite identificar a la persona, demostrar integridad, autenticidad y no repudio del contrato.'
    },
    {
        name: 'KYC',
        link: 'digital-checks',
        description: 'Comienza a verificar antecedentes de tus usuarios con el nivel más alto de detalle.',
        descriptionLong: 'Verifica antecedentes de tus clientes, sean personas naturales o jurídicas, garantizando compliance KYC/AML con listas vinculantes y no vinculantes, certificando la biometría facial de tus usuarios, y validando la integridad de la documentación suministrada.',
        moreInfo: 'Verifica antecedentes de tus clientes, sean personas naturales o jurídicas, garantizando compliance KYC/AML con listas vinculantes y no vinculantes.'
    }
]

export const digitalAuthentication = {
    
    subtitle: 'Realiza un onboarding a tus clientes en tan solo unos minutos de manera 100% digital.',
    description: 'Identifica a tus clientes en todos tus procesos: onboarding, login, checkout, firma de contratos u otros documentos',
    description2: 'Proceso de autenticación y validacion de identidad 100% digital utilizando modelos de comparacion para el reconocimiento, eliminando el riesgo de suplantación.',
    benefits: [
        'Mejorar la satisfacción del cliente y su fidelización, los clientes esperan una experiencia de usuario cada vez con menos fricción y documentación',
        'Liberar a la entidad financiera de esta tarea pudiendo emplear sus recursos en otros objetivos más valiosos',
        'Reducir el tiempo del procedimiento en cuestión de minutos',
        'La reducción de costes que aportan los procesos de onboarding digital es significativa, tanto en recursos materiales como humanos',
        'Aumenta el volumen de negocio ya que se reduce considerablemente la tasa de abandono, dada la sencillez y agilidad del proceso',
        'Entre más interacciones se generen en línea, el proceso de registro en plataformas es un punto crítico en la experiencia del usuario',
    ],
    benefitImages: [
        'products/digital-authentication/1.png',
        'products/digital-authentication/2.png',
        'products/digital-authentication/3.png',
        'products/digital-authentication/4.png',
        'products/digital-authentication/5.png',
        'products/digital-authentication/6.png'
    ],
    characteristics: [
        'Verificación de datos biométricos faciales con el documento de identificación en tiempo real',
        'Verificación del titular con el envío de contraseña para uso exclusivo de autenticación vía celular o mail',
        'Verificación de datos y fotos con redes sociales utilizando inteligencia artificial',
        'Cuestionarios aleatorios de información histórica crediticia y sociodemográfica para validación de identidad',
        'Verificación de datos personales con el archivo nacional de identificación',
        'Verificación de datos personales con bases de datos exclusivas y autenticadas',
        'Verificación de datos personales con los datos embebidos en el código de barras PDF417 del documento de identidad',
        'Verificación de datos personales con reconocimiento óptico de caracteres del documento de identidad',
        'Verificación de antecedentes en tiempo real para reducir los riesgos y el fraude asociados de LAFT',
    ],
    characteristicTitles: [
        'Biometría facial',
        'Validación con OTP',
        'Redes sociales',
        'Validación histórica',
        'Bases de datos gubernamentales',
        'Bases de datos abiertas',
        'Código de barras',
        'Validación OCR',
        'Validación listas restrictivas',
    ],
    characteristicImages: [
        'products/digital-authentication/7.png',
        'products/digital-authentication/8.png',
        'products/digital-authentication/9.png',
        'products/digital-authentication/10.png',
        'products/digital-authentication/11.png',
        'products/digital-authentication/12.png',
        'products/digital-authentication/13.png',
        'products/digital-authentication/14.png',
        'products/digital-authentication/15.png',
    ],
}

export const digitalChecks = {
    subtitle: 'Verifica antecedentes de tus clientes, sean personas naturales o jurídicas, garantizando compliance KYC/AML con listas vinculantes y no vinculantes, certificando la biometría facial de tus usuarios, y validando la integridad de la documentación suministrada.',
    description: 'Comienza a verificar antecedentes de tus usuarios ya sean personas naturales y jurídicas con el nivel más alto de biometría facial, validación de información y análisis de documentación en el mercado.',
    description2: 'Las fuentes de información son consultadas en el momento que solicitas el estudio y capturamos evidencias de las consultas realizadas donde se demuestra la fecha y hora en la que se ejecutó, las cuales pueden ser usadas como evidencia jurídica en la realización del proceso de debida diligencia.',
    description3: 'Know Your Customer permite a las compañías protegerse a sí mismas al garantizar que están haciendo negocios legalmente con entidades y personas legítimas, y también protege a nuestros clientes que de otra manera podrían ser perjudicados por un fraude o delito financiero.',
    benefits: [
        'Seguridad: Ayudamos a la toma de decisiones con base en el perfil de riesgo de las personas y empresas según sus datos públicos, socio económicos y legales',
        'Riesgo: Reduce la probabilidad de fraude asociados en tus procesos trabajo y selección',
        'Facilidad: Mejoramos la efectividad en los procesos de validación de terceros mediante un reporte íntegro y comprensivo',
        'Tiempo real: Consultas directamente sobre sobre las fuentes de información. No usamos bases de datos precargadas, solo resultados e información en 60 segundos',
        'Facilidad de uso: Las consultas se realizan en un solo clic, es posible realizar cientos de consultas paralelas y en pocos minutos',
        'Personalizable: Equipo técnico especializado a su disposición para realizar un servicio acorde a sus necesidades y requerimientos',
    ],
    benefitImages: [
        'products/digital-authentication/5.png',
        'products/digital-authentication/4.png',
        'products/digital-authentication/6.png',
        'products/digital-authentication/3.png',
        'products/digital-authentication/1.png',
        'products/digital-authentication/2.png',

    ],
    characteristics: [
        'Verificación de datos personales con el archivo nacional de identificación',
        'Revisión en listas restrictivas de órganos nacionales e internacionales de prevención de lavado de activos, financiación del terrorismo y narcotráfico',
        'Antecedentes e histórico judicial, policía, procuraduría nacional, juzgados y  tribunales',
        'Revisión de fuentes de datos nacionales fiscales y registro de constitución empresarial',
        'Antecedentes e histórico de tránsito, licencias, infracciones y multas',
        'Dashboard que centraliza los perfiles de sus clientes consultados con su documentación en tiempo real',
    ],
    characteristicTitles: [
        'Bases de datos gubernamentales',
        '',
        '',
        '',
        '',
    ],
    characteristicImages: [
        'products/digital-authentication/11.png',
        'products/digital-authentication/15.png',
        'products/digital-authentication/10.png',
        'products/digital-checks/1.png',
        'products/digital-authentication/12.png',
        'products/digital-checks/2.png',
    ],
}

export const digitalLending = {
    first: {
        description: 'Hemos creado una nueva experiencia fluida que modifica la forma tradicional de solicitar un crédito: desde la originación hasta el cierre 100% digital, para crédito de vehículo, micro crédito, libre inversión y empresarial.',
        title: 'Beneficios Transformación Digital',
        texts: [
            'Ofrecemos una solución completa de créditos de extremo a extremo que proporciona una base integral para bancos, cooperativas, microfinancieras, medianas  y pequeñas empresas que necesiten financiación digital para sus clientes y negocio.',
            'Asegurar y controlar  la estrategia de créditos digitales, modificando en tiempo real sus políticas de riesgo a conveniencia.'
        ],
        images: [
            'products/digital-lending/1.png',
            'products/digital-lending/2.png',
        ]
    },
    second: {
        title: 'Financiación en punto de venta y online',
        subtitle: 'Te permitimos cumplir con las demandas de presupuesto de ventas para mantener su negocio en movimiento',
        text: 'Con un enfoque completo de la gestión del riesgo de crédito, lo cubrimos desde la originación, análisis de riesgo, colocación y la presentación de informes en una solución simplificada, para que pueda concentrarse en los momentos importantes y en su negocio. Con una solución para financiación de sus clientes online o en punto de venta logramos ofrecerle a sus clientes una experiencia fácil, segura y 100% digital. Aumente las ventas de su negocio con una integración con CarlaTech para ofrecerle financiación a sus clientes en minutos desde cualquier dispositivo.' 
    }
}

export const digitalDocumentation = {
    subtitle: 'Elimina la necesidad de tener documentos, plantillas, pagarés y contratos físicos o que tus clientes tengan que firmar presencialmente la documentación requerida.',
    description: 'Este método elimina la necesidad de que nuestros clientes tengan documentos, plantillas, pagarés y contratos físicos o que sus clientes tengan que firmar presencialmente o acercarse a un lugar físico para la firma de la documentación requerida.',
    description2: 'Proceso de autenticación y validacion de identidad 100% digital utilizando modelos de comparacion para el reconocimiento, eliminando el riesgo de suplantación',
    characteristics: [
        'Por medio de nuestro aliado Deceval somos la primera empresa en Colombia que puede emitir pagarés digitales a nombre de nuestros aliados financieros y de terceros.',
        'Todos los documentos y plantillas digitales que el cliente requiera pueden firmarse de manera digital cumpliendo todos los estándares de seguridad.',
        'Manejamos dos tipos de almacenamiento para los documentos, plantillas y contratos digitales, uno como custodio nuestro aliado Deceval y otro almacenamiento en Blockchain por medio de nosotros.'
    ],
    characteristicImages: [
        'products/digital-signature/5.svg',
        'products/digital-signature/6.svg',
        'products/digital-signature/7.svg',
    ],
    benefits: [
        'Experiencia del cliente mejorada debido a la reducción del tiempo de respuesta sin papeleo. Te ofrecemos la oportunidad de revisar, modificar, transferir, eliminar o firmar cualquier documento electrónico desde un dispositivo con acceso a internet.',
        'Aumento de los ingresos a través de la optimización de costos operativos para el aliado financiero. Las entidades financieras han destacado que esta tecnología reduce los riesgos y costos operativos en su actividad.',
        'Reduce el riesgo de fraude y suplantación al utilizar servicios de autenticación digital, que incluyen más de 7 data points para incrementar la seguridad de los procesos para nuestros clientes y aliados.',
        'No solo te ofrecemos una solución respecto a los documentos físicos, sino que también garantizamos la seguridad, integridad, autenticidad y no repudio de cualquier documento electrónico por medio de tecnología Blockchain.'
    ],
    images: [
        'products/digital-signature/1.png',
        'products/digital-signature/2.png',
        'products/digital-signature/3.png',
        'products/digital-signature/4.png',
    ]
}

export const faas = {
    subtitle: 'Accede al mundo crediticio sin necesidad de desarrollos, APIs ni conexiones. Obtén todo lo que necesitas para prestar dinero y colocar productos de crédito en una plataforma auto-gestionada y totalmente personalizable a tu medida.',
    titles: [
        'Experiencia digital para nuestros aliados',
        'Créditos más fáciles',
        'Reducir la complejidad',
        'Plataforma única para la eficiencia',
        'ROI rápido',

    ],
    texts: [
        'Ofrecemos el portafolio de productos más completo de software integral de gestión de créditos, para crédito de vehículo, microcrédito, empresarial, libre inversión y vivienda. Creamos una experiencia de lender digital constante para entidades financieras, microfinancieras, cooperativas,  medianas y pequeñas empresas y consumidores.',
        'Cubriendo el ciclo de vida completo del crédito (originación, scoring, colocación, gestión de garantías), nuestras soluciones facilitan los préstamos al mejorar la experiencia de incorporación de nuevos clientes, aumentar la transparencia y agilizar las operaciones administrativas.',
        'Como el único proveedor de soluciones de arquitectura abierta para todos los tipos de préstamos, permitimos realizar una fácil integración y ayuda a reducir la complejidad y los costos asociados con la administración de múltiples sistemas.',
        'Nuestra tecnología de administración de créditos le brinda una visión de 360 ​​grados de todas las relaciones y facilidades bajo administración. Los cálculos, los flujos de trabajo, el análisis financiero, el cumplimiento electrónico y el servicio, todos trabajan juntos en una sola plataforma. Al eliminar los procesos manuales asociados, volver a escribir datos, duplicar esfuerzos, imprimir y enviar documentos por correo, nuestro software de gestión de créditos reduce los costos y brinda una eficiencia inevitable.',
        'Con una funcionalidad fácil de integrar a su negocio que presenta flujos de trabajo intuitivos y una curva de aprendizaje limitada, nuestras soluciones aseguran una adopción más rápida de los empleados y un ROI más rápido'
    ],
    images: [
        'products/faas.svg',
        'products/digital-signature.svg',
        'products/digital-verification.svg',
        'products/digital-checks.svg',
        'products/digital-lending.svg',
    ]

}