export const faq = [
    /*
     {
        title: '¿Qué es Fináncialo Mejor?',
        description: 'Somos un integrador y facilitador del ecosistema financiero, en el que los stakeholders pueden encontrar productos y servicios para optimizar y facilitar su objetivo.',
    },
    {
        title: '¿Cómo funciona el marketplace de créditos de Fináncialo Mejor?',
        description: 'Es un Marketplace que le permite al usuario buscar y comparar al instante y sin ningún costo, ofertas de crédito personalizadas y ajustadas a su realidad financiera. En la parte superior del sitio el usuario deberá elegir el tipo de producto en el que está interesado, responderá preguntas de información personal y de información del crédito. Al elegir monto y plazo el simulador de crédito mostrará las ofertas acordes a su realidad financiera. Cada oferta incluirá información como: Pago aproximado por cuota, tasa aproximada y más información que permitirá conocer las condiciones que puede llegar a tener en su crédito financiero.',
    },
    {
        title: '¿Para quienes es el marketplace de créditos de Fináncialo Mejor?',
        description: 'Para todas las personas naturales y jurídicas.',
        list: [
            'Personas Naturales: Deben ser mayores de edad, que ya cuente con un historial crediticio o que esté pronto a iniciarlo. Estudiantes, profesionales, empleados, pensionados, trabajadores independientes y empresarios pueden utilizar los servicios de Fináncialo Mejor.',
            'Personas jurídicas: Deben estar constituidas en Colombia y que ya cuenten con un historial crediticio pueden utilizar los servicios de Fináncialo Mejor. Se incluyen todos los sectores de la economía colombiana.']
    },
    {
        title: '¿Qué beneficios tengo al ser usuario de Fináncialo Mejor?',
        list: [
            'Rápido y fácil: Poder consultar y acceder a ofertas de crédito en pocos pasos y sin costo.',
            'Ofertas de crédito: Podrá buscar y comparar ofertas de crédito personalizadas de acuerdo a su necesidad y perfil.',
            'Créditos pre-aprobados: Una vez se realice la verificación respectiva de la información registrada por el ciudadano, podrá obtener pre-aprobados en línea siempre y cuando todo esté de acuerdo con las reglas y políticas financieras establecidas de nuestros aliados financieros.',
            'Confianza: Tener el respaldo y sentirse seguro de una gran marca con el tratamiento de los datos personales.',
            'Cuidar tu score: No afectará tu score, como es ir banco a banco buscando préstamos, afectando tu score crediticio al ser consultado en varias entidades.',
            'Educación financiera: A través de contenidos buscamos que los ciudadanos desarrollen valores, conocimientos y las competencias necesarias para tomar decisiones de manera responsable.'
        ],
    },
     */
    
    {
        title: '¿Cuánto tiempo permanece un reporte negativo en mi historia de crédito?',
        description: 'La Ley Habeas Data (artículo 13 de la Ley 1266 de 2008 y la Sentencia C-1011 de 2008 de la Corte constitucional) establece que la información negativa que refleja incumplimientos o moras tendrá un plazo máximo de permanencia de 4 años, contados a partir de la fecha de pago o extinción de la obligación. Si la mora en el pago es inferior a dos años, el reporte permanecerá por el doble del tiempo de la mora, contados a partir de que sean pagadas las cuotas vencidas, o que se extinga la obligación por cualquier modo. Por ejemplo, si estuviste en mora durante dos meses y te pusiste al día en tus obligaciones, el reporte negativo permanecerá durante 4 meses. Es importante señalar que, si estabas en mora y pagaste, el historial crediticio refleja que la obligación se encuentra al día. Sin embargo, el pasado de mora que presentaste será eliminado conforme a los términos que acabamos de explicar.',
    },
    {
        title: '¿Necesito mejorar mi Perfil de Crédito?',
        description: 'Tradicionalmente las entidades financieras se han encargado de analizar el Perfil de Crédito de las personas y en conjunto con las centrales de riesgo han construido modelos analíticos que predicen el desempeño crediticio de una persona basándose en su situación actual y su comportamiento histórico. Es importante mejorar el Perfil de Crédito no sólo para contar con un mayor acceso al crédito, sino también para disminuir tu riesgo de incumplimiento, mejorar tus finanzas y alcanzar tus metas personales. Un buen Perfil de Crédito no sólo se construye pagando a tiempo, otras variables, como un endeudamiento adecuado, una correcta utilización de productos como tarjetas de crédito y créditos rotativos, y en general un buen equilibrio en tu flujo de caja y tu exposición al riesgo hacen parte de un buen Perfil de Crédito.',
    },
    {
        title: '¿Qué es una central de riesgo?',
        description: 'Las centrales de riesgo o centrales de información (Datacrédito Experian, Cifin, entre otras) son entidades privadas que poseen bases de datos donde las entidades registran las operaciones y el comportamiento crediticio de las personas naturales y jurídicas. Las entidades que reportan información a una central de riesgo pueden ser: bancos, entidades financieras, cooperativas, empresas de telecomunicaciones y del sector real. La información que está contenida en estas bases de datos es tanto positiva como negativa.',
        description2: 'La central de riesgo se encarga de almacenar, procesar y suministrar información sobre el comportamiento de pago de las obligaciones de las personas naturales y jurídicas. Esta información es usada al momento de otorgar un crédito, pero no es el único factor considerado, pues las entidades son autónomas y establecen sus propias políticas de aprobación de créditos. Es importante señalar que Fináncialo Mejor no aprueba o niega créditos, cada entidad tiene sus propias políticas y toma sus decisiones.'
    },
    {
        title: '¿Cada cuánto se actualiza la información en las centrales de riesgo?',
        description: 'Respecto al estado de cuentas, hábitos de consumo, pagos de intereses y saldos de créditos vigentes, la información se actualiza todos los meses, tomando como base la información que suministran las entidades financieras que en general reportan su información mes vencido, empresas de telecomunicaciones y establecimientos comerciales.',
    },
    {
        title: '¿Qué es un Score Crediticio?',
        description: 'Es un puntaje numérico que predice tu probabilidad de cumplimiento en el pago de tus obligaciones financieras en los próximos meses, es tu tarjeta de presentación ante el mercado financiero. Un buen puntaje te abre puertas para recibir préstamos, financiamiento u otros recursos por parte de las empresas. Este Score sirve para que las entidades que otorgan crédito puedan determinar, de manera rápida y consistente, el nivel de riesgo o de incumplimiento de pago. Entre más alto sea el Score, menor es el riesgo y será mejor candidato para obtener un crédito o producto financiero. Debido a todo lo anterior, conocer y administrar el score crediticio es la mejor decisión para tu futuro.',
    },
    {
        title: '¿Cómo se calcula el score crediticio?',
        description: 'Tu información de crédito está en constante cambio y el score resume en un puntaje todos los elementos que afectan el nivel de riesgo crediticio de una persona. No solamente el pagar o no tus obligaciones afecta tu score de crédito o puntaje de crédito, otros factores como experiencia crediticia, utilizaciones y cupos disponibles en tus tarjetas de crédito, saldos y cuotas de tus créditos, aperturas recientes de productos, diversidad de tu portafolio, número de productos abiertos y cerrados, cupos disponibles en rotativos, entre otros, también son incluidos en el cálculo del score. Tanto tu situación actual como tu comportamiento histórico son tenidos en cuenta en el cálculo del Score de Crédito.',
    },
    {
        title: '¿Qué podría afectar mi buen perfil crediticio?',
        description: 'Los pagos extemporáneos de cuotas crediticias (con moras de 30, 60 o más días) reducen tu calificación y te hacen menos atractivo para que las entidades financieras te otorguen préstamos. Las frecuentes consultas de entidades financieras en cortos períodos de tiempo también opacan tu perfil crediticio, cada vez que solicitas un crédito eres consultado por las entidades para ver tu experiencia y el estado actual en el sistema financiero y de esta consulta queda el respectivo registro en el historial de crédito. Lo anterior, se utiliza para indicar que estás gestionando simultáneamente varios créditos, lo cual podría desbordar tu capacidad de pago. O bien sea porque alguna de las entidades ha negado el crédito y se busca entonces obtenerlo en otra y así sucesivamente.',
    },
    {
        title: '¿Cómo puedo mejorar mi perfil para tramitar un crédito?',
        list: [
            'Mantener al día todas y cada una de las obligaciones financieras.',
            'Bancarizando todas tus transacciones. Es decir, moviendo tu dinero a través de cuentas de ahorro o corrientes.',
            'Adquiriendo un portafolio crediticio desde que se cumple la mayoría de edad. Por ejemplo, una tarjeta de crédito con un cupo moderado es una buena manera de iniciar la experiencia financiera.',
            'Programando las cuotas de las tarjetas de crédito al menor tiempo posible y tratando de mantener libre el máximo cupo que se pueda.',
            'Reducir la cantidad de créditos y/o los saldos de capital pendientes también te resultará de gran ayuda para incrementar tu capacidad de endeudamiento.',
            'Solicitar únicamente los créditos necesarios y autorizar sólo cuando sea estrictamente necesario el que terceros consulten tu historial crediticio en las centrales de riesgo. Quienes así lo hacen son considerados menos riesgosos para la aprobación de un préstamo.'
        ]
    },
    {
        title: '¿Qué datos tienen en cuenta las entidades financieras para evaluar y analizar el perfil crediticio de una persona?',
        description: 'En el aspecto laboral, analizan la clase de trabajo, el tipo de contrato y por cuánto tiempo has laborado en la empresa. También ponderan la relación de gastos fijos relacionados con manutención y la de los miembros de tu hogar, tales como vivienda (arriendo y pago de servicios públicos), alimentación, transporte y educación, entre otros.',
        description2: 'Adicionalmente, incluyen la información del patrimonio que tengas representado en inversiones, bienes raíces, vehículos o maquinaria y el correcto reporte y pago de los impuestos correspondientes a retención en la fuente y declaración de renta; el cumplimiento de estas obligaciones te ayudará a dar una imagen positiva respecto al compromiso y cultura de pago.',
        description3: 'Tu historial crediticio es sumamente importante. Los pagos puntuales y un endeudamiento que te permita cumplir de manera correcta con tus obligaciones te brindarán mayor posibilidad al momento de solicitar un préstamo, ya que las entidades esperan que los préstamos funcionen como una solución y un apalancamiento para obtener un cómodo desarrollo personal, familiar o empresarial y no que éste se convierta en una carga para el titular y sus dependientes.'
    },
    {
        title: '¿En caso de suplantación de identidad qué debo hacer?',
        description: 'Si eres víctima de fraude por suplantación, ten en cuenta estas recomendaciones para actuar a tiempo:',
        list: [
            '1. Llamar a la entidad en donde se realizó el fraude, notificar el fraude, pedir nombre de la persona que recibió tu solicitud y el número de radicación del caso. Ten estos datos presentes; junto con la fecha y hora en la que realizaste esta llamada a la entidad.',
            '2. Asegurarse durante la llamada del bloqueo de los productos abiertos de forma fraudulenta.',
            '3. Si el fraude se produjo por robo o extravío de documentos, puedes generar el denuncio en línea en la página de la policía en www.policia.gov.co',
        ]
    },
    {
        title: '¿En qué consiste el procedimiento Know Your Customer (KYC)?',
        description: 'Para cumplir con las regulaciones internacionales contra el lavado de dinero y el financiamiento del terrorismo, los procedimientos KYC deben implementarse en la primera etapa de cualquier relación comercial con un nuevo cliente. KYC comienza con la tarea de recopilación de datos personales para verificar que los clientes son quienes dicen ser. En el sector financiero, esto implica la verificación de su identidad a través de documentos, por ejemplo, el documento nacional de identidad. Para algunas instituciones financieras, este proceso se sigue realizando de forma presencial y en papel, pero para otras, ya se ha convertido en un trámite 100% digital que incluso incluye el uso de comprobaciones biométricas adicionales, como el reconocimiento facial o de huellas dactilares, para reconocer al titular del documento.',
    },
]

export const about = {
    title: 'Cambiando la experiencia bancaria',
    subtitle: 'Desde el año 2018, hemos reunido a entidades financieras y aliados, transformando la forma en que las personas acceden a servicios financieros. En los últimos dos años, hemos ayudado a miles de personas a tomar el control de sus finanzas, hacer crecer su empresa e invertir para su futuro.',
    subtitle2: 'Somos un integrador y facilitador del ecosistema financiero, en el que todas las partes de interés pueden encontrar productos y servicios para alcanzar su objetivo',
    subtitleImage: 'products/digital-signature/5.png',
    description: 'Todos merecen sentirse seguros de sus finanzas. Nuestro trabajo es brindarle las herramientas, la educación y las oportunidades que necesita para lograr un progreso real y significativo.',
    description2: 'Estamos trabajando para crear un proceso que sea más simple y fácil con el objetivo de promover la inclusión financiera en Colombia.',
    description3: 'Integramos todas las partes de interés del ecosistema financiero por medio de conexiones digitales ofreciendo una solución digital para todos nuestros aliados.',
    vision: [
        {
            'title': 'Reinventar los servicios financieros',
            'description': 'Somos una empresa de tecnología basada en la creencia de que las soluciones innovadoras y creativas ofrecen más valor y una mejor experiencia.'
        },
        {
            'title': 'Innovar de la manera correcta',
            'description': 'Estamos liderando la creación de una nueva industria, desarrollando formas éticas y responsables para brindar mayor valor y mejores oportunidades a nuestros clientes.'
        },
        {
            'title': 'Brindar experiencias de clase mundial',
            'description': 'El acceso al crédito debe ser perfecto. Estamos comprometidos a hacer que los préstamos e inversiones sean simples y fáciles para todos.'
        },
        {
            'title': 'Oportunidades para todos',
            'description': 'Todos merecen un mejor futuro financiero. Hemos creado un mercado que mantiene los costos bajos y las oportunidades altas.'
        },
    ],
    how: [
        {
            'title': 'Obtienes tus puntajes e informes',
            'description': 'Obtén acceso a tus puntajes de crédito e informes de DataCredito, con actualizaciones semanales.'
        },
        {
            'title': 'Hacemos recomendaciones',
            'description': 'Utilizamos nuestro amor por los datos para analizar su perfil de crédito y hacer recomendaciones de productos que podrían ayudarlo a ahorrar dinero.'
        },
        {
            'title': 'El banco o prestamista nos paga',
            'description': 'Si obtiene un producto a través de una de nuestras recomendaciones, el banco o prestamista nos paga.'
        },
    ],
    end: 'Te invitamos a ser parte del cambio digital y convertirnos en el mayor colocador de créditos digitales de Latinoamérica.'
}